import React from "react";
import { Link } from "react-router-dom";
import { fetchingData } from "../../api";
import { isWindowView } from "../../helpers";

// Default

export const defaultFilterListTahun = [2019, 2020, 2021, 2022, 2023, 2024];
export const defaultFilterTahun = 2022;
export const defaultFilterAngkatan = 2021;

export const defaultFilterTahunAjaran = 2021;
export const defaultFilterSemesterAjaran = 2;

// End Default

// filter tahun
export const FilterTahun = (props) => {
  const { onChangeTahun, value } = props;

  return (
    <div className="p-1 rounded">
      <label className="mb-2" htmlFor="">Pilih Tahun</label>
      <select
        className="form-select bg-light"
        onChange={(e) => {
          onChangeTahun(e.target.value);
          fetchingData();
        }}
        defaultValue={value}
      >
        <option value="">Pilih Tahun</option>
        {defaultFilterListTahun.length > 0 &&
          defaultFilterListTahun.map((i, index) => {
            return (
              <React.Fragment key={index}>
                <option value={i}>{i}</option>
              </React.Fragment>
            );
          })}
      </select>
    </div>
  );
};

// filter bulan
export const FilterBulan = (props) => {
  const { onChangeBulan, value } = props;

  return (
    <div className="p-1 rounded">
      <label className="mb-2" htmlFor="">Pilih Bulan</label>
      <select
        onChange={(e) => {
          onChangeBulan(e.target.value);
          fetchingData();
        }}
        className="form-select bg-light"
        defaultValue={value}
      >
        <option value="1">Januari</option>
        <option value="2">Februari</option>
        <option value="3">Maret</option>
        <option value="4">April</option>
        <option value="5">Mei</option>
        <option value="6">Juni</option>
        <option value="7">Juli</option>
        <option value="8">Agustus</option>
        <option value="9">September</option>
        <option value="10">Oktober</option>
        <option value="11">November</option>
        <option value="12">Desember</option>
      </select>
    </div>
  );
};

export const LayoutAddBtn = (props) => {
  const { link, add, onClick } = props;
  return (
    <div className="row justify-content-end mb-3">
      <div className="col-md-6 text-right">
        {link && (
          <Link
            className={`btn btn-primary ${
              isWindowView() === false && "btn-sm"
            }`}
            to={link}
            dangerouslySetInnerHTML={{ __html: add }}
          ></Link>
        )}
        {onClick && (
          <button
            className={`btn btn-primary ${
              isWindowView() === false && "btn-sm"
            }`}
            onClick={onClick}
            dangerouslySetInnerHTML={{ __html: add }}
          ></button>
        )}
      </div>
    </div>
  );
};

export const LayoutTopFilter = (props) => {
  return (
    <>
      <div className="row mb-3 justify-content-end">
        {props.onChangeSearch && (
          <div className="col-md-6">
            <input
              onKeyUp={(e) => props.onChangeSearch(e)}
              className="form-control"
              type="text"
              placeholder="Search . . ."
            />
          </div>
        )}
      </div>
      {props.onChangeCabang && (
        <div className="col-md-4 mb-3">
          <select
            onChange={(e) => props.onChangeCabang(e)}
            className="custom-select"
          >
            <option value="">Semua Cabang</option>
            <option value="Pusat">Pusat</option>
            <option value="Denpasar">Denpasar</option>
            <option value="Singaraja">Singaraja</option>
            <option value="Karangasem">Karangasem</option>
          </select>
        </div>
      )}
      {props.onChangeBulan && (
        <div className="col-md-4 mb-3">
          <select
            onChange={(e) => props.onChangeBulan(e)}
            className="custom-select"
          >
            <option value="1">Januari</option>
            <option value="2">Februari</option>
            <option value="3">Maret</option>
            <option value="4">April</option>
            <option value="5">Mei</option>
            <option value="6">Juni</option>
            <option value="7">Juli</option>
            <option value="8">Agustus</option>
            <option value="9">September</option>
            <option value="10">Oktober</option>
            <option value="11">November</option>
            <option value="12">Desember</option>
          </select>
        </div>
      )}
    </>
  );
};

// Pagination
export const FilterPagination = (props) => {
  const { onChange } = props;
  return (
    <div className="bg-light p-1 rounded">
      <label htmlFor="">Data per Page</label>
      <select
        className="form-control"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
      >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
  );
};

// Search
export const FilterSearch = (props) => {
  const { onChange } = props;

  let timer = 0;
  const handleChange = (val) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      onChange(val);
      fetchingData();
    }, 1000);
  };

  return (
    <div className="bg-light p-1 rounded">
      <label htmlFor="">Search</label>
      <input
        type="text"
        className="form-control"
        placeholder="Search . . ."
        onKeyUp={(e) => handleChange(e.target.value)}
      />
    </div>
  );
};

// Angkatan
export const FilterAngkatan = (props) => {
  const { onChange, value } = props;

  let timer = 0;
  const handleChange = (val) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      onChange(val);
      fetchingData();
    }, 800);
  };

  return (
    <div className="bg-light p-1 rounded">
      <label htmlFor="">Angkatan</label>
      <input
        type="text"
        className="form-control"
        onKeyUp={(e) => {
          handleChange(e.target.value);
        }}
        placeholder="Angkatan"
        defaultValue={value}
      />
    </div>
  );
};

// Jurusan
// export const FilterJurusan = (props) => {
//   const { onChange } = props;

//   const [items, setItems] = useState({});

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const res = await fetchJurusan("all");
//         setItems(res.data);
//       } catch (err) {
//         console.log(err);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div className="bg-light p-1 rounded">
//       <label htmlFor="">Jurusan</label>
//       <select
//         className="form-control"
//         onChange={(e) => {
//           onChange(e.target.value);
//           fetchingData();
//         }}
//       >
//         <option value="">Pilih Jurusan</option>
//         {items.length >= 1 &&
//           items.map((i, index) => {
//             return (
//               <React.Fragment key={index}>
//                 <option value={i.id}>
//                   {index + 1}. {i.nama}
//                 </option>
//               </React.Fragment>
//             );
//           })}
//       </select>
//       <span className="text-danger">
//         Note : Saat ini filter jurusan hanya dapat digunakan untuk Mhs angkatan
//         2021
//       </span>
//     </div>
//   );
// };

// Jenjang
export const FilterJenjang = (props) => {
  const { onChange } = props;
  return (
    <div className="bg-light p-1 rounded">
      <label htmlFor="">Jenjang</label>
      <select
        className="form-control"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
      >
        <option value="">Pilih Jenjang</option>
        <option value="D1">D1</option>
        <option value="D2">D2</option>
      </select>
    </div>
  );
};

// Status
export const FilterStatus = (props) => {
  const { onChange } = props;
  return (
    <div className="bg-light p-1 rounded">
      <label htmlFor="">Status Mahasiswa</label>
      <select
        className="form-control"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
      >
        <option value="">Pilih Status</option>
        <option value="0" className="text-success">
          Aktif
        </option>
        <option value="9" className="text-danger">
          Nonaktif
        </option>
      </select>
    </div>
  );
};

// StatusPendaftar
export const FilterStatusPendaftar = (props) => {
  const { onChange } = props;
  return (
    <div className="bg-light p-1 rounded">
      <label htmlFor="">Status Pendaftar</label>
      <select
        className="form-control"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
      >
        <option value="">Pilih Status</option>
        <option value="0">Belum Daftar</option>
        <option value="1">Sudah Daftar</option>
        <option value="2">Sudah DU</option>
        <option value="3">Sudah Cair</option>
        <option value="4">Mhs Aktif</option>
        <option value="9">Batal Daftar</option>
      </select>
    </div>
  );
};

// tahun ajaran
export const FilterTahunAjaran = (props) => {
  const { valueTahun, valueSemester, onChangeTahun, onChangeSemester } = props;

  return (
    <div className="py-2">
      <div className="row mb-3">
        <div className="col-lg-6">
          <div className="my-1">
          <label className="mb-2" htmlFor="">Tahun Ajaran</label>
          <select
            className="form-select bg-light"
            onChange={(e) => {
              onChangeTahun(e.target.value);
              fetchingData();
            }}
            defaultValue={valueTahun}
          >
            <option value="">Pilih Tahun</option>
            {defaultFilterListTahun.length > 0 &&
              defaultFilterListTahun.map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    <option value={i}>{i}</option>
                  </React.Fragment>
                );
              })}
          </select>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="my-1">
          <label className="mb-2" htmlFor="">Semester</label>
          <select
            className="form-select bg-light"
            onChange={(e) => {
              onChangeSemester(e.target.value);
              fetchingData();
            }}
            defaultValue={valueSemester}
          >
            <option value="">Pilih Semester</option>
            <option value={1}>Ganjil</option>
            <option value={2}>Genap</option>
          </select>
          </div>
        </div>
      </div>
    </div>
  );
};

// tanggal
export const FilterTanggal = (props) => {
  const { onChange, value } = props;

  return (
    <div className="bg-light p-1 rounded">
      <label htmlFor="">Pilih Tanggal</label>
      <input
        type="date"
        className="form-control"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
        defaultValue={value}
      />
    </div>
  );
};
