import React, { useCallback, useEffect, useState } from 'react'
import { FilterTahunAjaran } from '../../components/layouts/TopContent'
import { getGenapGanjil, getOrigin } from '../../helpers';
import { useRecoilValue } from 'recoil';
import { tokenState, userState } from '../../storages/auth';
import { fetchHasilMonitoring } from '../../api';
import { semesterAjaranState } from '../../storages';

function HasilMonitoring() {

    const realTimeTahun = new Date().getFullYear();
    const sA = useRecoilValue(semesterAjaranState);

    const user = useRecoilValue(userState);
    const token = useRecoilValue(tokenState);
    const [getUrl, setGetUrl] = useState("");
    const [tahun, setTahun] = useState(realTimeTahun);
    const [semester, setSemester] = useState(sA);
    const [loading, setLoading] = useState(true);

    const fetchData = useCallback( async () => {
        setLoading(true);
        try {
            await fetchHasilMonitoring(tahun, semester, user, token);
            setGetUrl(`${getOrigin()}/api/storage/dosen/hasil-monitoring/${tahun}/${semester}/${user.nip}.pdf`);
            setLoading(false);
        } catch (error) {
            setGetUrl("");
            setLoading(false);
        }
    }, [tahun, semester, user, token]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

  return (
    <>
      <div className='row'>
        <div className='col-lg-12 mt-2 px-2 pt-2'>
          <div className='bg-white rounded-3'>
            <div className="bg-primary text-white rounded-top-3 shadow py-2">
              <span className='d-flex justify-content-center align-items-center'><i className='fa fa-fw fa-chart-simple me-1' /> Hasil Monitoring</span>
            </div>
            <div className='px-3 mt-4'>
                <FilterTahunAjaran
                    valueTahun={realTimeTahun}
                    valueSemester={sA}
                    onChangeTahun={(res) => setTahun(res)}
                    onChangeSemester={(res) => setSemester(res)}
                />

                <div className='mt-4'>
                    <AngketView loading={loading} tahun={tahun} semester={semester} getUrl={getUrl}/>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const AngketView = ({loading, tahun, semester, getUrl }) => {

return (
    <div className='mb-4'>
        {loading ? (
            <div className='d-flex justify-content-center align-items-center p-5 text-black rounded-3'>
                <p className='mb-0'><i className='fa fa-fw fa-spinner fa-spin'/> Loading...</p>
            </div>
        ) 
        : 
            getUrl ? (
                <div className="ratio ratio-1x1">
                    <iframe
                        className="ratio ratio-1x1"
                        title="Honor"
                        src={getUrl}
                        allowFullScreen
                    ></iframe>
                </div>
            ) : (
                <div className='d-flex justify-content-center align-items-center p-5 text-black rounded-3'>
                    {semester ?
                        <div className='text-center'>
                            <i className='fa fa-fw fa-circle-xmark text-danger'/>
                            <p className='mb-0'>Tidak ada file pada semester {getGenapGanjil(semester)} {tahun}</p>
                        </div>
                        :
                        <div className='text-center'>
                            <i className='fa fa-fw fa-circle-exclamation text-warning'/>
                            <p className='mb-0'>Silahkan pilih semester terlebih dahulu.</p>
                        </div>
                    }
                </div>
            )
        }
    </div>
)
};

export default HasilMonitoring