import React from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { postLogout } from "../../api";
import { saAlert, saConfirm } from "../../helpers";
import { semesterAjaranState, tahunAjaranState } from "../../storages";
import { tokenState } from "../../storages/auth";

const LayoutsTopbar = () => {
  const [token, setToken] = useRecoilState(tokenState);
  const setUser = useSetRecoilState(tokenState);
  const setTahunAjaran = useSetRecoilState(tahunAjaranState);
  const setSemesterAjaran = useSetRecoilState(semesterAjaranState);

  const handleLogout = () => {
    saConfirm("warning", "Yakin keluar akun?").then((res) => {
      if (res.isConfirmed) {
        postLogout(token).then((res) => {
          if (res.data === "success") {
            setToken("");
            setUser({});
            setTahunAjaran("");
            setSemesterAjaran("");
            localStorage.removeItem("_tokenMhs");
            saAlert("success", "Anda keluar dari akun.");
          }
        });
      }
    });
  };

  // const [menuMobile, setMenuMobile] = useState(false);

  return (
    <>
    {/* <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            SIDOS - Alfa Prima
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setMenuMobile(!menuMobile)}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className={`collapse navbar-collapse justify-content-end ${
              menuMobile ? "show" : ""
            }`}
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                  onClick={() => setMenuMobile(false)}
                >
                  <i className="fa fa-home" />
                  {" Home"}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/kelas-jadwal"
                  onClick={() => setMenuMobile(false)}
                >
                  <i className="fa fa-book" />
                  {" Kelas"}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={handleLogout}
                  className="nav-link active"
                  aria-current="page"
                  to="#"
                >
                  <i className="fa fa-sign-out-alt" />
                  {" Logout"}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div> */}






    <div>
        <nav className="navbar bg-primary shadow-nice rounded-3 m-1 fixed-top">
          <div className="container-fluid">
            <div>
              <Link
                className="navbar-brand text-white d-flex align-items-center p-0"
                to="/"
              >
                <img src="/img/logo/AP-W.png" alt="" width={36} height={36} />
                <span className="fs-5">SIDOS - ALFA PRIMA</span>
              </Link>
            </div>

            {/* <div className="mobile-hidden">
              <div className="d-flex align-items-center">
                <ul className="nav">
                  <li className="nav-item">
                    <Link to="/" className="nav-link link-silver">
                      <i className="fa fa-fw fa-home" /> Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/kelas-jadwal" className="nav-link link-silver">
                      <i className="fa fa-fw fa-user-graduate" /> Kelas
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/honor" className="nav-link link-silver">
                      <i className="fa fa-fw fa-sack-dollar" /> Honor <NewLabel />
                    </Link>
                  </li>
                </ul>
                <button onClick={handleLogout} className="ms-3 me-1 btn btn-sm btn-danger border-0 fw-semibold px-3">
                  <i className="align-middle fa fa-fw fa-power-off" /> Keluar akun
                </button>
              </div>
            </div> */}

            <button
              className="navbar-toggler border-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <span className="fa fa-fw fa-bars link-warning py-1 fs-3" />
            </button>

            <div
              className="offcanvas offcanvas-end bg-light shadow-nice rounded-3 border-0"
              data-bs-scroll="false"
              data-bs-backdrop="true"
              tabIndex={-1}
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div className="offcanvas-header bg-primary rounded-top-3 shadow-xl d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <img src="/img/logo/AP-W.png" alt="" width={36} height={36} />
                  <h5
                    className="offcanvas-title text-white fs-5"
                    id="offcanvasNavbarLabel"
                  >
                    SIDOS - ALFA PRIMA
                  </h5>
                </div>
                <i
                  className="fa fa-fw fa-close text-gray-400 fs-4 shadow-nice rounded-2 p-1"
                  type="button"
                  data-bs-dismiss="offcanvas"
                />
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav justify-content-end flex-grow-1">
                  <li className="nav-item" data-bs-dismiss="offcanvas">
                    <Link
                      className="nav-link border-bottom link-primary"
                      to="/"
                    >
                      <i className="fa fa-fw fa-home fs-5 me-2" /> Home
                    </Link>
                  </li>
                  <li className="nav-item" data-bs-dismiss="offcanvas">
                    <Link
                      className="nav-link border-bottom link-primary"
                      to="/kelas-jadwal"
                    >
                      <i className="fa fa-fw fa-user-graduate fs-5 me-2" />{" "}
                      Kelas
                    </Link>
                  </li>
                  <li className="nav-item" data-bs-dismiss="offcanvas">
                    <Link
                      className="nav-link border-bottom link-primary"
                      to="/honor"
                    >
                      <i className="fa fa-fw fa-sack-dollar fs-5 me-2" />{" "}
                      Honor <NewLabel/>
                    </Link>
                  </li>
                  <li className="nav-item" data-bs-dismiss="offcanvas">
                    <Link
                      className="nav-link border-bottom link-primary"
                      to="/angket"
                    >
                      <i className="fa fa-fw fa-people-group fs-5 me-2" />{" "}
                      Angket <NewLabel/>
                    </Link>
                  </li>
                  <li className="nav-item" data-bs-dismiss="offcanvas">
                    <Link
                      className="nav-link border-bottom link-primary"
                      to="/hasil-monitoring"
                    >
                      <i className="fa fa-fw fa-chart-simple fs-5 me-2" />{" "}
                      Hasil Monitoring <NewLabel/>
                    </Link>
                  </li>
                  <li className="nav-item" data-bs-dismiss="offcanvas">
                    <button
                      onClick={handleLogout}
                      className="nav-link btn btn-sm btn-danger-2 border-0 rounded-3 shadow mt-3 py-1 w-100"
                    >
                      <i className="align-middle fa fa-fw fa-power-off fs-5" /> Keluar Akun
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export const NewLabel = () => {
  return (
    <span className="bg-warning text-black fw-semibold px-2 rounded ms-1 text-sm">Baru</span>
  );
}

export default LayoutsTopbar;
