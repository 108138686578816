import React, { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { postNilai } from "../../api";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import Table, { Thead } from "../../components/Table";
import { saAlert } from "../../helpers";
import { tokenState } from "../../storages/auth";

const NilaiCreate = (props) => {
  const { kelas, mhs, krs, onFetchData } = props;

  return (
    <>

      <div className="px-3 my-4">
        <div className='row gx-4 gy-0'>
            <div className='col-lg-4 text-center'>
            <NilaiTugas
              kelas={kelas}
              mhs={mhs}
              krs={krs}
              onFetchData={onFetchData}
            />
            </div>
            <div className='col-lg-4 text-center'>
            <NilaiUts
              kelas={kelas}
              mhs={mhs}
              krs={krs}
              onFetchData={onFetchData}
            />
            </div>
            <div className='col-lg-4 text-center'>
            <NilaiUas
              kelas={kelas}
              mhs={mhs}
              krs={krs}
              onFetchData={onFetchData}
            />
            </div>
        </div>
      </div>
    </>
  );
};

const NilaiTugas = (props) => {
  const { kelas, mhs, krs, onFetchData } = props;

  const token = useRecoilValue(tokenState);
  const [modal, setModal] = useState(false);
  const tagForm = useRef();

  const handleOpen = () => {
    setModal(true);
  };

  const [form, setForm] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    postNilai({ data: form, nilai_tugas: true }, token)
      .then((res) => {
        if (res.data === "success") {
          saAlert("success", "Berhasil update Nilai Tugas !");
          onFetchData();
          handleClose();
        }
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setForm({});
    setModal(false);
  };

  return (
    <>
      <button onClick={handleOpen} className='btn btn-sm btn-success-2 shadow w-100 px-4 my-1'><i className='fa fa-fw fa-edit'/> Input Nilai Tugas</button>

      <Modal
        title={`Nilai Tugas Kelas ${kelas.kode}`}
        modal={modal}
        sizeLg
        onClose={handleClose}
      >
        <>
          {/* <p className="text-warning">
            Gunakan tanda titik untuk menggantikan tanda koma
          </p> */}
          <form ref={tagForm} onSubmit={handleSubmit}>
            <Table 
              className='table-bordered border-secondary table-hover'
              title='Tabel Nilai Tugas'
              text='Saat input nilai tugas, gunakan tanda titik untuk menggantikan tanda koma'
              textColor='text-warning'
            >
              <Thead>
                <tr className="align-middle text-center">
                  <th className='bg-primary-4 text-white fw-normal'>No</th>
                  <th className='bg-primary-4 text-white fw-normal'>NIM</th>
                  <th className='bg-primary-4 text-white fw-normal'>Nama</th>
                  <th className='bg-primary-4 text-white fw-normal'>
                    <p className="my-0">Nilai Tugas</p>
                    <p className="mobile-hidden my-0 text-sm text-gray-500">(Klik kolom dibawah untuk input nilai tugas)</p>
                  </th>
                </tr>
              </Thead>
              <tbody>
                {mhs.length > 0 &&
                  mhs.filter((i) => parseInt(i.status) !== 9).map((i, index) => {
                    return (
                      <tr key={index} className="align-middle">
                        <td className="bg-none text-white text-center">
                          {index + 1}
                        </td>
                        <td className="bg-none text-white text-center">{i.nim}</td>
                        <td className="bg-none text-white">{i.nama}</td>
                        <td className="bg-none text-white p-0">
                          <Input
                            className='bg-none text-white border-0 rounded-0 shadow-none'
                            inputSm
                            name={index}
                            placeholder="Nilai. . ."
                            value={
                              krs.length &&
                              krs
                                .filter(
                                  (k) => parseInt(k.mhs_id) === parseInt(i.id)
                                )
                                .map((k) => k.nilai && k.nilai.tugas)
                            }
                            onChange={(e) => {
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: {
                                    krs_id:
                                      krs.length > 0 &&
                                      krs
                                        .filter(
                                          (k) =>
                                            parseInt(k.mhs_id) ===
                                            parseInt(i.id)
                                        )
                                        .map((k) => k.id)[0],
                                    tugas: e.target.value,
                                  },
                                };
                              });
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div className="mobile-justify-center btn-group">
              <button type="submit" className="btn btn-sm btn-primary-2">
                Simpan
              </button>
              <button
                type="button"
                className="btn btn-sm btn-dark border-0 shadow-none"
                onClick={handleClose}
              >
                Kembali
              </button>
            </div>
          </form>
        </>
      </Modal>
    </>
  );
};

const NilaiUts = (props) => {
  const { kelas, mhs, krs, onFetchData } = props;

  const token = useRecoilValue(tokenState);
  const [modal, setModal] = useState(false);
  const tagForm = useRef();

  const handleOpen = () => {
    setModal(true);
  };

  const [form, setForm] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    postNilai({ data: form, nilai_uts: true }, token)
      .then((res) => {
        if (res.data === "success") {
          saAlert("success", "Berhasil update Nilai UTS !");
          onFetchData();
          handleClose();
        }
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setForm({});
    setModal(false);
  };

  return (
    <>
    <button onClick={handleOpen} className='btn btn-sm btn-success-2 shadow w-100 px-4 my-1'><i className='fa fa-fw fa-edit'/> Input Nilai UTS</button>

      <Modal
        title={`Nilai UTS Kelas ${kelas.kode}`}
        modal={modal}
        sizeLg
        onClose={handleClose}
      >
        <>
          <form ref={tagForm} onSubmit={handleSubmit}>
            <Table
              className='table-bordered border-secondary table-hover'
              title='Tabel Nilai UTS'
              text='Saat input nilai UTS, gunakan tanda titik untuk menggantikan tanda koma'
              textColor='text-warning'
            >
              <Thead>
                <tr className="align-middle text-center">
                  <th className="bg-primary-4 text-white fw-normal">No</th>
                  <th className="bg-primary-4 text-white fw-normal">NIM</th>
                  <th className="bg-primary-4 text-white fw-normal">Nama</th>
                  <th className='bg-primary-4 text-white fw-normal'>
                    <p className="my-0">Nilai UTS</p>
                    <p className="mobile-hidden my-0 text-sm text-gray-500">(Klik kolom dibawah untuk input nilai UTS)</p>
                  </th>
                </tr>
              </Thead>
              <tbody>
                {mhs.length > 0 &&
                  mhs.filter((i) => parseInt(i.status) !== 9).map((i, index) => {
                    return (
                      <tr key={index} className="align-middle">
                        <td className="bg-none text-white text-center">
                          {index + 1}
                        </td>
                        <td className="bg-none text-white text-center">{i.nim}</td>
                        <td className="bg-none text-white">{i.nama}</td>
                        <td className="bg-none p-0">
                          <Input
                            className='bg-none text-white border-0 shadow-none rounded-0'
                            name={index}
                            placeholder="Nilai. . ."
                            value={
                              krs.length &&
                              krs
                                .filter(
                                  (k) => parseInt(k.mhs_id) === parseInt(i.id)
                                )
                                .map((k) => k.nilai && k.nilai.uts)
                            }
                            onChange={(e) => {
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: {
                                    krs_id:
                                      krs.length > 0 &&
                                      krs
                                        .filter(
                                          (k) =>
                                            parseInt(k.mhs_id) ===
                                            parseInt(i.id)
                                        )
                                        .map((k) => k.id)[0],
                                    uts: e.target.value,
                                  },
                                };
                              });
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div className="mobile-justify-center btn-group">
              <button type="submit" className="btn btn-sm btn-primary-2">
                Simpan
              </button>
              <button
                type="button"
                className="btn btn-sm btn-dark border-0 shadow-none"
                onClick={handleClose}
              >
                Kembali
              </button>
            </div>
          </form>
        </>
      </Modal>
    </>
  );
};

const NilaiUas = (props) => {
  const { kelas, mhs, krs, onFetchData } = props;

  const token = useRecoilValue(tokenState);
  const [modal, setModal] = useState(false);
  const tagForm = useRef();

  const handleOpen = () => {
    setModal(true);
  };

  const [form, setForm] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    postNilai({ data: form, nilai_uas: true }, token)
      .then((res) => {
        if (res.data === "success") {
          saAlert("success", "Berhasil update Nilai UAS !");
          onFetchData();
          handleClose();
        }
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setForm({});
    setModal(false);
  };

  return (
    <>
    <button onClick={handleOpen} className='btn btn-sm btn-success-2 shadow w-100 px-4 my-1'><i className='fa fa-fw fa-edit'/> Input Nilai UAS</button>

      <Modal
        title={`Nilai UAS Kelas ${kelas.kode}`}
        modal={modal}
        sizeLg
        onClose={handleClose}
      >
        <>
          <form ref={tagForm} onSubmit={handleSubmit}>
            <Table
              className='table-bordered border-secondary table-hover'
              title='Tabel Nilai UAS'
              text='Saat input nilai UAS, gunakan tanda titik untuk menggantikan tanda koma'
              textColor='text-warning'
            >
              <Thead>
                <tr className="align-middle text-center">
                  <th className='bg-primary-4 text-white fw-normal'>No</th>
                  <th className='bg-primary-4 text-white fw-normal'>NIM</th>
                  <th className='bg-primary-4 text-white fw-normal'>Nama</th>
                  <th className='bg-primary-4 text-white fw-normal'>
                    <p className="my-0">Nilai UAS</p>
                    <p className="mobile-hidden my-0 text-sm text-gray-500">(Klik kolom dibawah untuk input nilai UAS)</p>
                  </th>
                </tr>
              </Thead>
              <tbody>
                {mhs.length > 0 &&
                  mhs.filter((i) => parseInt(i.status) !== 9).map((i, index) => {
                    return (
                      <tr key={index}>
                        <td className="bg-none text-white text-center align-middle">
                          {index + 1}
                        </td>
                        <td className="bg-none text-white text-center align-middle">{i.nim}</td>
                        <td className="bg-none text-white align-middle">{i.nama}</td>
                        <td className="bg-none p-0">
                          <Input
                            className='bg-none text-white border-0 shadow-none'
                            name={index}
                            placeholder="Nilai. . ."
                            value={
                              krs.length &&
                              krs
                                .filter(
                                  (k) => parseInt(k.mhs_id) === parseInt(i.id)
                                )
                                .map((k) => k.nilai && k.nilai.uas)
                            }
                            onChange={(e) => {
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: {
                                    krs_id:
                                      krs.length > 0 &&
                                      krs
                                        .filter(
                                          (k) =>
                                            parseInt(k.mhs_id) ===
                                            parseInt(i.id)
                                        )
                                        .map((k) => k.id)[0],
                                    uas: e.target.value,
                                  },
                                };
                              });
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div className="mobile-justify-center btn-group">
              <button type="submit" className="btn btn-sm btn-primary-2">
                Simpan
              </button>
              <button
                type="button"
                className="btn btn-sm btn-dark border-0 shadow-none"
                onClick={handleClose}
              >
                Tutup
              </button>
            </div>
          </form>
        </>
      </Modal>
    </>
  );
};

export default NilaiCreate;
