import React from "react";

const NoData = (props) => {
  const { title } = props;
  return (
    <table className='table table-sm table-borderless'>
        <tbody>
            <tr className='align-middle text-nowrap text-center'>
                <td colSpan={12} className='py-5 bg-silver text-secondary rounded'><span className='d-block py-5'><i className='fa fa-fw fa-exclamation-circle text-secondary fs-5 me-1'/><p className='my-0'>{title ? title : "Tidak ada data tersedia."}</p></span></td>
            </tr>
        </tbody>
    </table>
  );
};

export default NoData;
