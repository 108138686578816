import React from "react";

const Input = (props) => {
  const {
    id,
    label,
    type,
    customFront,
    name,
    value,
    error,
    placeholder,
    readOnly,
    onChange,
    inputSm,
    marginBottom,
    className,
    hidden,
    accept,
  } = props;
  return (
    <div className={`${marginBottom && 'mb-3'}`}>
      {label && (
        <label
          className="form-label"
          htmlFor={id}
          dangerouslySetInnerHTML={{ __html: label }}
        ></label>
      )}
      {type === "date" || type === "datetime-local" || type === "time" ? (
        <div className={customFront && "d-flex"}>
          {customFront && <span className="mr-1 mt-1">{customFront}</span>}
          <input
            className={`form-control ${placeholder && 'placeholder-white'} ${error && "is-invalid"} ${inputSm && "form-control-sm"} ${className}`}
            onChange={onChange}
            onFocus={onChange}
            onMouseUp={onChange}
            onPaste={onChange}
            type={type}
            name={name}
            defaultValue={value}
            placeholder={placeholder}
            readOnly={readOnly}
            hidden={hidden}
            accept={accept}
          />
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      ) : (
        <div className={customFront && "d-flex"}>
          {customFront && <span className="mr-1 mt-1">{customFront}</span>}
          <input
            className={`form-control ${placeholder && 'placeholder-white'} ${error && "is-invalid"} ${inputSm && "form-control-sm"} ${className}`}
            onKeyUp={onChange}
            onChange={onChange}
            onFocus={onChange}
            onPaste={onChange}
            type={type}
            name={name}
            defaultValue={value}
            placeholder={placeholder}
            readOnly={readOnly}
            hidden={hidden}
            accept={accept}
          />
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      )}
      <div className="invalid-feedback">{error}</div>
    </div>
  );
};

export default Input;
