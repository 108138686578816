import React from 'react'

function Footer() {

  return (
    <>
        <div className='text-center py-2 m-3 rounded-3 small'>
            <span>Copyright &copy; Alfa Prima {new Date().getFullYear()}</span>
        </div>
    </>
  )
}

export default Footer