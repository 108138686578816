import React from 'react'

function LoginAs(props) {

    const {loginName} = props

  return (
    <>
        <h5 className='fs-6 my-2 text-gray-700 text-center'><i className='fa fa-fw fa-user-tie me-1'></i>{loginName}</h5>
    </>
  )
}

export default LoginAs