import config from "axios";
import { getOrigin, saToast } from "../helpers";

const axios = config.create({
  baseURL: getOrigin(),
});

export const fetchingData = (
  icon = "success",
  title = "Loading, please wait !"
) => {
  saToast(icon, title);
};

export const headers = (token) => {
  return { headers: { Authorization: `Bearer ${token}` } };
};

export const loginAuth = (data) => {
  fetchingData();
  return axios.post("/api/login", data);
};

export const checkAuth = (token) => {
  return axios.get("/api/check-dosen", headers(token));
};

// Logout
export const postLogout = (token) => {
  fetchingData();
  return axios.post(`/api/logout`, null, headers(token));
};

// Dosen
// Update
export const patchDosen = (id, data, token) => {
  fetchingData();
  return axios.patch(`/api/dosen/${id}`, data, headers(token));
};

// Kelas
export const fetchKelas = (page = 1, token, custom = "") => {
  return axios.get(`/api/kelas?page=${page}&${custom}`, headers(token));
};
// show
export const showKelas = (id, token, custom = "") => {
  return axios.get(`/api/kelas/${id}?${custom}`, headers(token));
};

// Pengjaran
// index
export const fetchPengajaran = (page = 1, token, custom = "") => {
  return axios.get(`/api/pengajaran?page=${page}&${custom}`, headers(token));
};
// show
export const showPengajaran = (id, token, custom = "") => {
  return axios.get(`/api/pengajaran/${id}?${custom}`, headers(token));
};
// post
export const postPengajaran = (data, token, custom = "") => {
  fetchingData();
  return axios.post(`/api/pengajaran?${custom}`, data, headers(token));
};
// Update
export const patchPengajaran = (id, data, token) => {
  fetchingData();
  return axios.patch(`/api/pengajaran/${id}`, data, headers(token));
};

// Absensi
// post
export const postAbsensi = (data, token, custom = "") => {
  fetchingData();
  return axios.post(`/api/absensi?${custom}`, data, headers(token));
};
// delete
export const deleteAbsensi = (id, token, custom = "") => {
  fetchingData();
  return axios.delete(`/api/absensi/${id}?${custom}`, headers(token));
};

// nilai
// index
export const fetchNilai = (page = 1, token, custom = "") => {
  return axios.get(`/api/nilai?page=${page}&${custom}`, headers(token));
};
// post
export const postNilai = (data, token, custom = "") => {
  fetchingData();
  return axios.post(`/api/nilai?${custom}`, data, headers(token));
};
// show
export const showNilai = (id, token, custom = "") => {
  return axios.get(`/api/nilai/${id}?${custom}`, headers(token));
};

// krs
// show
export const showKrs = (id, token, custom = "") => {
  return axios.get(`/api/krs/${id}?${custom}`, headers(token));
};

// pengumuman
export const fetchPengumuman = (page = 1, token, custom = "") => {
  return axios.get(`/api/pengumuman?page=${page}&${custom}`, headers(token));
};

// honor
export const fetchHonor = (tahun, bulan, user, token) => {
  return axios.get(`/api/storage/dosen/slip-gaji/${tahun}/${bulan}/${user.nip}.pdf`, headers(token));
};

// angket
export const fetchAngket = (tahun, semester, user, token) => {
  return axios.get(`/api/storage/dosen/angket/${tahun}/${semester}/${user.nip}.pdf`, headers(token));
};

// hasil monitoring
export const fetchHasilMonitoring = (tahun, semester, user, token) => {
  return axios.get(`/api/storage/dosen/hasil-monitoring/${tahun}/${semester}/${user.nip}.pdf`, headers(token));
};
