import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LayoutsMain from "../components/layouts/Main";
import DosenEdit from "../pages/dosen/Edit";
import DosenEditPassword from "../pages/dosen/EditPassword";
import Home from "../pages/Home";
import KelasJadwal from "../pages/kelas/Jadwal";
import KelasListMhs from "../pages/kelas/ListMhs";
import KelasPengajaran from "../pages/kelas/Pengajaran";
import KelasRekapAbsensi from "../pages/kelas/RekapAbsensi";
import Nilai from "../pages/nilai/Nilai";
import NoMatch from "../pages/NoMatch";
import PengajaranAbsensi from "../pages/pengajaran/Absensi";
import Honor from "../pages/honor/Honor";
import Angket from "../pages/angket/Angket";
import HasilMonitoring from "../pages/hasil-monitoring/HasilMonitoring";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <LayoutsMain>
            <Home />
          </LayoutsMain>
        </Route>
        {/* Dosen */}
        <Route path="/dosen-edit">
          <LayoutsMain>
            <DosenEdit />
          </LayoutsMain>
        </Route>
        <Route path="/dosen-edit-password">
          <LayoutsMain>
            <DosenEditPassword />
          </LayoutsMain>
        </Route>
        {/* Kelas */}
        <Route path="/kelas-jadwal">
          <LayoutsMain>
            <KelasJadwal />
          </LayoutsMain>
        </Route>
        <Route path="/pengajaran/:kelas_id">
          <LayoutsMain>
            <KelasPengajaran />
          </LayoutsMain>
        </Route>
        <Route path="/kelas-list-mhs/:kelas_id">
          <LayoutsMain>
            <KelasListMhs />
          </LayoutsMain>
        </Route>
        <Route path="/kelas-rekap-absensi/:kelas_id">
          <LayoutsMain>
            <KelasRekapAbsensi />
          </LayoutsMain>
        </Route>
        {/* Pengajaran */}
        <Route path="/pengajaran-absensi/:pengajaran_id">
          <LayoutsMain>
            <PengajaranAbsensi />
          </LayoutsMain>
        </Route>
        {/* Nilai */}
        <Route path="/nilai/:kelas_id">
          <LayoutsMain>
            <Nilai />
          </LayoutsMain>
        </Route>
        {/* honor */}
        <Route path="/honor">
          <LayoutsMain>
            <Honor />
          </LayoutsMain>
        </Route>
        {/* angket */}
        <Route path="/angket">
          <LayoutsMain>
            <Angket />
          </LayoutsMain>
        </Route>
        {/* hasil monitoring */}
        <Route path="/hasil-monitoring">
          <LayoutsMain>
            <HasilMonitoring />
          </LayoutsMain>
        </Route>
        {/* 404 */}
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
