import React from 'react'

function Container(props) {

    const {pageTitle, children} = props

  return (
    <>

        <div className='pt-4 mt-5'>
            <div className='position-relative container px-0'>

                {pageTitle ?
                (
                <div className='py-3'>
                    <h1 className='fw-semibold text-center py-2 my-0 fs-5'>{pageTitle}</h1>
                </div>
                ) : (
                <div className='pt-5'>
                    {/* <h1 className='fw-semibold text-center py-2 my-0 fs-5'>{pageTitle}</h1> */}
                </div>
                )}

                {children}

            </div>
        </div>
    </>
  )
}

export default Container