import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchKelas } from "../../api";
import { FilterTahunAjaran } from "../../components/layouts/TopContent";
import Loading from "../../components/Loading";
// import NoData from "../../components/NoData";
// import Table, { Thead, Tbody, Tr, Th, Td } from "../../components/Table";
import { getHari } from "../../helpers";
import {
  semesterAjaranState,
  tahunAjaranState,
  titleState,
} from "../../storages";
import { tokenState } from "../../storages/auth";

const KelasJadwal = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const setTitle = useSetRecoilState(titleState);

  const tA = useRecoilValue(tahunAjaranState);
  const sA = useRecoilValue(semesterAjaranState);

  useEffect(() => {
    setTitle("Jadwal Kelas");
  }, [setTitle]);

  const token = useRecoilValue(tokenState);

  const [tahunAjaran, setTahunAjaran] = useState(tA);
  const [semesterAjaran, setSemesterAjaran] = useState(sA);

  const [items, setItems] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchKelas(
        "all",
        token,
        `tahun_ajaran=${tahunAjaran}&semester_ajaran=${semesterAjaran}`
      );
      setItems(res.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [token, tahunAjaran, semesterAjaran]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

    return (
      <>

<div className='row'>
        <div className='col-lg-12 mt-2 px-2 pt-2'>
            <div className='bg-white rounded-3'>
                <div className="bg-primary text-white rounded-top-3 shadow py-2">
                    <span className='d-flex justify-content-center align-items-center'><i className='fa fa-fw fa-chart-line me-1' /> Jadwal Kelas</span>
                </div>
              
                    {isLoaded ? (
                    <>
                    <div className='px-3 mt-4'>
                      {/* <h6 className='mb-2'>Kode icon warna mata kuliah :</h6>
                      <div className='row row-cols-2 gy-2 px-3 mb-2'>
                          <div className='col-lg-3 px-1'>
                              <div className='d-flex align-items-center rounded bg-primary shadow text-white p-2 h-100'>
                                  <h6 className='my-0 text-sm'><i className='text-warning me-1 fa fa-fw fa-circle'/>= Mata kuliah praktek</h6>
                              </div>
                          </div>
                          <div className='col-lg-3 px-1'>
                              <div className='d-flex align-items-center rounded bg-primary shadow text-white p-2 h-100'>
                                  <h6 className='my-0 text-sm'><i className='text-info me-1 fa fa-fw fa-circle'/>= Mata kuliah teori</h6>
                              </div>
                          </div>
                      </div>
                      <h6 className='mb-2'>Kode opsi icon :</h6>
                      <div className='row row-cols-2 gy-2 px-3 mb-2'>
                          <div className='col-lg-3 px-1'>
                              <div className='d-flex align-items-center rounded bg-primary shadow text-white p-2 h-100'>
                                  <h6 className='my-0 text-sm'><i className='me-1 fa fa-fw fa-user-graduate'/>= List Mahasiswa</h6>
                              </div>
                          </div>
                          <div className='col-lg-3 px-1'>
                              <div className='d-flex align-items-center rounded bg-primary shadow text-white p-2 h-100'>
                                  <h6 className='my-0 text-sm'><i className='me-1 fa fa-fw fa-chalkboard'/>= Pengajaran</h6>
                              </div>
                          </div>
                          <div className='col-lg-3 px-1'>
                              <div className='d-flex align-items-center rounded bg-primary shadow text-white p-2 h-100'>
                                  <h6 className='my-0 text-sm'><i className='me-1 fa fa-fw fa-star'/>= Nilai</h6>
                              </div>
                          </div>
                      </div> */}
                      
                      <FilterTahunAjaran
                          valueTahun={tahunAjaran}
                          valueSemester={semesterAjaran}
                          onChangeTahun={(res) => setTahunAjaran(res)}
                          onChangeSemester={(res) => setSemesterAjaran(res)}
                        />

                        {items && items.length > 0 ? (
                       <>
                          {/* <Table
                            title='Tabel Jadwal Kelas'
                            copyTable
                          >
                            <Thead>
                                <Tr>
                                  <Th className='rounded-start-3'><i className='fa fa-fw fa-cog pt-1'/></Th>
                                  <Th className='border-start border-secondary'>No</Th>
                                  <Th className='border-start border-secondary px-4'>Kode</Th>
                                  <Th className='border-start border-secondary px-5'>Mata Kuliah</Th>
                                  <Th className='border-start border-secondary px-4'>Hari & Jam (WITA)</Th>
                                  <Th className='border-start border-secondary px-4'>Ruangan</Th>
                                  <Th className='border-start border-secondary'>Tahun Ajaran</Th>
                                  <Th className='border-start border-secondary'>Silabus & RPP</Th>
                                  <Th className='border-start border-secondary'>Modul & Bahan Ajar</Th>
                                  <Th className='border-start border-secondary rounded-end-3'>Opsi</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {items.map((i, index) => {
                                return (
                                  <>
                                    <Tr key={index}>
                                        <Td className='rounded-start-3'><i className={`fa fa-fw fa-lg fa-circle ${
                                        i.matkul &&
                                        i.matkul.jenis_matkul === 1 &&
                                        "text-warning"
                                      } ${
                                        i.matkul &&
                                        i.matkul.jenis_matkul === 2 &&
                                        "text-info"
                                      } pt-1`}/></Td>
                                        <Td className='border-start'>{index + 1}</Td>
                                        <Td className='border-start'>{i.kode}</Td>
                                        <Td className='border-start'>{i.matkul && i.matkul.nama}</Td>
                                        <Td className='border-start'>{i.jamkul && `${getHari(i.jamkul.hari)}, ${i.jamkul.dari} - ${i.jamkul.sampai}`}</Td>
                                        <Td className='border-start'>{i.ruangan && i.ruangan.nama}</Td>
                                        <Td className='border-start'>{i.tahun_ajaran}</Td>
                                        <Td className='border-start'>{i.matkul && i.matkul.rpp_link && (<button onClick={() => window.open(i.matkul && i.matkul.rpp_link)} className='border-0 link-decoration-info border-bottom fw-semibold text-sm'>{i.matkul && i.matkul.rpp_link && "Klik disini"}</button>)}</Td>
                                        <Td className='border-start'>{i.matkul && i.matkul.bahan_ajar_link && (<button onClick={() => window.open(i.matkul && i.matkul.bahan_ajar_link)} className='border-0 link-decoration-info border-bottom fw-semibold text-sm'>{i.matkul && i.matkul.bahan_ajar_link && "Klik disini"}</button>)}</Td>
                                        <Td className='border-start rounded-end-3'>
                                            <Link to={`/kelas-list-mhs/${i.id}`} title='List Mahasiswa' className='btn btn-sm btn-success-2 rounded-3 mx-1'><i className='fa-sm fa fa-fw fa-user-graduate'/></Link>
                                            <Link to={`/pengajaran/${i.id}`} title='Pengajaran' className='btn btn-sm btn-success-2 rounded-3 mx-1'><i className='fa-sm fa fa-fw fa-chalkboard'/></Link>
                                            <Link to={`/nilai/${i.id}`} title='Nilai' className='btn btn-sm btn-success-2 rounded-3 mx-1'><i className='fa-sm fa fa-fw fa-star'/></Link>
                                        </Td>
                                    </Tr>
                                  </>
                                );
                              })}
                            </Tbody>
                          </Table> */}

                          <div className="row g-4 rounded-4 row-size pt-0 pb-4 d-flex justify-content-center">
                          {items.map((i, index) => {
                                  return (
                                    <div className="col-lg-4" key={index}>
                                      <div
                                        className="rounded-3 bg-gradient-primary shadow-nice p-4 border-bottom-warning h-100"
                                      >
                                        <div className="border-bottom border-secondary pb-2 d-flex justify-content-between align-items-start mb-2">
                                          <div>
                                            <h5 className="text-warning fw-semibold fst-italic">
                                              {i.kode}
                                            </h5>
                                            <div className="d-flex align-items-center text-gray-500">
                                              <i className="fa fa-sm fa-fw fa-thumbtack me-1" />
                                              {i.matkul && i.matkul.jenis_matkul === 1 && <p className="mb-0 fw-semibold text-sm">PRAKTEK</p>}
                                              {i.matkul && i.matkul.jenis_matkul === 2 && <p className="mb-0 fw-semibold text-sm">TEORI</p>}
                                            </div>
                                          </div>
                                          <div>
                                            <h6 className="mb-0 fw-semibold text-white">{i.jamkul && getHari(i.jamkul.hari)}</h6>
                                          </div>
                                        </div>
    
                                        <table className="table table-sm table-borderless">
                                          <tbody>
                                            <tr>
                                              <td className="bg-none small text-gray-500">
                                                Nama Mata Kuliah
                                              </td>
                                              <td className="bg-none small text-white">
                                                :
                                              </td>
                                              <td className="bg-none small text-white">
                                                {i.matkul && i.matkul.nama}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="bg-none small text-gray-500">
                                                Semester
                                              </td>
                                              <td className="bg-none small text-white">
                                                :
                                              </td>
                                              <td className="bg-none small text-white">
                                                Semester {i.semester}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="bg-none small text-gray-500">
                                                Hari & Jam (WITA)
                                              </td>
                                              <td className="bg-none small text-white">
                                                :
                                              </td>
                                              <td className="bg-none small text-white">
                                                {i.jamkul && `${getHari(i.jamkul.hari)}, ${i.jamkul.dari} - ${i.jamkul.sampai}`}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="bg-none small text-gray-500">
                                                Ruangan
                                              </td>
                                              <td className="bg-none small text-white">
                                                :
                                              </td>
                                              <td className="bg-none small text-white">
                                                {i.ruangan && i.ruangan.nama}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="bg-none small text-gray-500">
                                                Tahun Ajaran
                                              </td>
                                              <td className="bg-none small text-white">
                                                :
                                              </td>
                                              <td className="bg-none small text-white">
                                                {i.tahun_ajaran}
                                              </td>
                                            </tr>
                                            <tr>
                                            <td className="bg-none small text-gray-500">
                                              Silabus & RPP
                                            </td>
                                            <td className="bg-none small text-white">
                                              :
                                            </td>
                                            <td className="bg-none">
                                              {i.matkul && i.matkul.rpp_link &&
                                                <button
                                                  className="btn btn-sm btn-primary-2 rounded-5 shadow text-sm px-3 py-0"
                                                  onClick={() => window.open(i.matkul && i.matkul.rpp_link)}
                                                >
                                                  <i className="fa fa-fw fa-book-open me-2 text-sm" />
                                                  buka disini
                                                </button>
                                              }
                                            </td>
                                          </tr>
                                            <tr>
                                            <td className="bg-none small text-gray-500">
                                              Modul & Bahan Ajar
                                            </td>
                                            <td className="bg-none small text-white">
                                              :
                                            </td>
                                            <td className="bg-none">
                                              {i.matkul && i.matkul.bahan_ajar_link &&
                                                <button
                                                  className="btn btn-sm btn-primary-2 rounded-5 shadow text-sm px-3 py-0"
                                                  onClick={() => window.open(i.matkul && i.matkul.bahan_ajar_link)}
                                                >
                                                  <i className="fa fa-fw fa-book-open me-2 text-sm" />
                                                  buka disini
                                                </button>
                                              }
                                            </td>
                                          </tr>
                                          </tbody>
                                        </table>
    
                                        <div className="btn-group shadow w-100">
                                            {/* <Link
                                              className="btn btn-sm btn-success-2"
                                              to={`/pengajaran-absensi/${i.id}`}
                                            >
                                              <i className="fa fa-fw fa-edit me-1"></i>
                                              Absensi & BAP
                                            </Link> */}

                                            <Link to={`/kelas-list-mhs/${i.id}`} title='List Mahasiswa' className='btn btn-sm btn-success-2 rounded-3 mx-1'><i className='fa fa-user-graduate me-1'/> List Mahasiswa</Link>
                                            <Link to={`/pengajaran/${i.id}`} title='Pengajaran' className='btn btn-sm btn-success-2 rounded-3 mx-1'><i className='fa fa-chalkboard me-1'/> Pengajaran</Link>
                                            <Link to={`/nilai/${i.id}`} title='Nilai' className='btn btn-sm btn-success-2 rounded-3 mx-1'><i className='fa fa-star me-1'/> Nilai</Link>
                                        </div>
                                      </div>
                                    </div>
                                );
                              })}
                        </div>
                       </>
                          
                        ) : (
                          <table className='table table-sm table-borderless'>
                              <tbody>
                                  <tr className='align-middle text-nowrap text-center'>
                                      <td colSpan={12} className='py-5 bg-silver text-secondary rounded'><span className='d-block py-5'><i className='fa fa-fw fa-exclamation-circle text-secondary fs-5 me-1'/><p className='my-0'>Saat ini belum ada Jadwal Kelas.</p></span></td>
                                  </tr>
                              </tbody>
                          </table>
                        )}
                    </div>
                    </>
                    ) : (
                      <Loading />
                    )}

                    
                
            </div>

        </div>

    </div>
      </>
    );
};

export default KelasJadwal;
