import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
// import { titleState } from "../../storages";
import { tokenState, userState } from "../../storages/auth";
import LayoutsTopbar from "./Topbar";
import Footer from "./Footer";
import Container from "./Container";
import LoginAs from "../LoginAs";
import { fetchPengumuman } from "../../api";
import ModalPrimary from "../ModalPrimary";
import ModalWarning from "../ModalWarning";

const LayoutsMain = (props) => {
  const { children } = props;

  // const title = useRecoilValue(titleState);
  const user = useRecoilValue(userState);
  const token = useRecoilValue(tokenState);

  const [itemsPem, setItemsPem] = useState([]);
  const [currentPem, setCurrentPem] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchPengumuman("all", token);
      // console.log(res);
      // console.log(res.data.pengumuman);
      setItemsPem(res.data.pengumuman);
      setCurrentPem(0);
      setShowModal(true);
    };

    fetchData();
  }, [token]);

  const nextPengumuman = () => {
    if (currentPem < itemsPem.length - 1) {
      setCurrentPem(prevIndex => prevIndex + 1);
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  const showPengumuman = itemsPem[currentPem];

  return (
    <>
      {showPengumuman && (
        currentPem % 2 === 0 ? (
          <ModalPrimary 
          show={showModal}
          onClose={nextPengumuman}
          judul={showPengumuman && showPengumuman.judul}
          deskripsi={showPengumuman && showPengumuman.isi}
          dari={showPengumuman && showPengumuman.dari.split('-').reverse().join('-')}
          sampai={showPengumuman && showPengumuman.sampai.split('-').reverse().join('-')}
          file={showPengumuman && showPengumuman.file}
        />
        ) : (
          <ModalWarning 
            show={showModal}
            onClose={nextPengumuman}
            judul={showPengumuman && showPengumuman.judul}
            deskripsi={showPengumuman && showPengumuman.isi}
            dari={showPengumuman && showPengumuman.dari.split('-').reverse().join('-')}
            sampai={showPengumuman && showPengumuman.sampai.split('-').reverse().join('-')}
            file={showPengumuman && showPengumuman.file}
          />
        )
      )}

      <LayoutsTopbar />
      <Container>
        <div className="bg-white shadow rounded-3 mx-2 px-1 pt-3">
          <LoginAs loginName={user.nama} />
            {children}
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default LayoutsMain;
