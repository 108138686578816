import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { patchDosen } from "../../api";
import Input from "../../components/Input";
import Loading from "../../components/Loading";
import { saAlert } from "../../helpers";
import { titleState } from "../../storages";
import { tokenState, userState } from "../../storages/auth";

const DosenEdit = () => {
  const setTitle = useSetRecoilState(titleState);
  const [user, setUser] = useRecoilState(userState);
  const token = useRecoilValue(tokenState);
  const history = useHistory();

  useEffect(() => {
    setTitle(`Update Profile ${user.nama}`);
    setForm(user);
  }, [setTitle, user.nama, user]);

  const [form, setForm] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    patchDosen(user.id, { ...form, update_profile: true }, token)
      .then((res) => {
        if (res.data) {
          setUser(res.data);
          history.push("/");
          saAlert("success", "Berhasil update profile.");
        }
      })
      .catch((err) => console.log(err));
  };

  if (user) {
    return (
      <div className="row">
        <div className="col-lg-12 mt-2 px-2 pt-2">
          <div className="bg-white rounded-3">
            <div className="bg-primary text-white rounded-top-3 shadow py-2">
              <span className="d-flex justify-content-center align-items-center">
                <i className="fa fa-fw fa-pen me-1" /> Ubah Profile
              </span>
            </div>
            <div className="px-3 my-4">
            <form onSubmit={handleSubmit}>
              <div className="row gx-3 gy-0">
                <div className="col-lg-6">
                  <Input
                    marginBottom
                    label="Normor HP / WA"
                    name="no_hp"
                    value={user.no_hp}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return { ...prevState, [e.target.name]: e.target.value };
                      })
                    }
                  />
                </div>
                <div className="col-lg-6">
                  <Input
                    marginBottom
                    label="Email Aktif"
                    name="email"
                    value={user.email}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return { ...prevState, [e.target.name]: e.target.value };
                      })
                    }
                  />
                </div>
              </div>
                <div className="mobile-justify-center btn-group mt-2">
                  <button className="btn btn-sm btn-success-3">Update</button>
                  <Link className="btn btn-sm btn-dark border-0 shadow-none px-3" to="/">
                    Batal
                  </Link>
                </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default DosenEdit;
