import React from 'react'
import { Modal } from 'react-bootstrap';
import { getOrigin } from '../helpers';

function ModalPrimary(props) {
    const {judul, style, deskripsi, dari, sampai, file, show, onClose} = props
    // const [show, setShow] = useState(true);

    // const openModal = () => {
    //     setShow(true);
    // }
    // const closeModal = () => {
    //     setShow(false);
    // }

  return (
    <>
    <Modal
        // data-bs-theme="light"
        show={show}
        onHide={onClose}
        backdrop='static'
        keyboard={false}
        style={style}
        animation={true}
        // dialogClassName='modal-dialog-scrollable'
        contentClassName='show bg-gradient-warning-2 rounded-5 text-gray-300 border-0 border-bottom-primary position-relative shadow-nice'
        centered
    >
        <div className='z-3 position-relative d-flex justify-content-center align-items-center'>
            <div className='position-absolute d-inline bg-primary rounded-pill border-bottom-warning shadow-nice' style={{top: '-48px'}}>
                <div className="d-flex justify-content-center align-items-center">
                    <img src="/img/logo/AP-W.png" alt="" width={108} height={108} className='pt-2' style={{filter: 'brightness(100%)'}}/>
                </div>
            </div>
        </div>

        <button onClick={onClose} className='z-1 position-absolute btn link-primary border-0 shadow-none p-0' style={{top: '10px', right: '10px'}}><i className='align-middle fa fa-fw fa-circle-xmark fa-xl fa-regular'/></button>

        <Modal.Body className='rounded-5 p-0 overflow-hidden'>
            <div>
                <div className='position-relative d-flex justify-content-center align-items-center'>
                    <div className='position-absolute d-inline' style={{top: '36px', opacity: '5%'}}>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className='bg-alfaprima' style={{padding: '256px', filter: 'brightness(0%)'}}/>
                        </div>
                    </div>
                </div>

            </div>

            <div className='p-4 mt-5'>

                <h2 className='text-center text-black fs-2 fw-semibold text-shadow border-bottom border-dark-transparent pb-3 mt-2 mb-3'>PENGUMUMAN</h2>
                <h1 className='mb-0 fs-5 text-center fw-semibold text-black'>{judul}</h1>
                {/* <h1 className='mb-0 fs-5 text-center text-black'>Acara Pernikahan kak dwika</h1> */}
                <p className='text-sm text-center fw-semibold text-black'>{dari} s/d {sampai}</p>
                <p className='text-sm text-black fw-semibold text-justify' style={{whiteSpace: 'pre-line'}}>{deskripsi}</p>
            </div>

        </Modal.Body>

            <div className='position-absolute start-0 w-50 h-50 m-4 overflow-hidden'>
                <div className='position-absolute bg-primary d-inline-block' style={{top: '-70px', left: '-20px', padding: '8rem 6px', opacity: '100%', rotate: '45deg'}}/>
                <div className='position-absolute bg-primary d-inline-block' style={{top: '-70px', left: '5px', padding: '8rem 6px', opacity: '80%', rotate: '45deg'}}/>
                <div className='position-absolute bg-primary d-inline-block' style={{top: '-70px', left: '30px', padding: '8rem 6px', opacity: '60%', rotate: '45deg'}}/>
            </div>

            <div className='position-absolute end-0 w-50 h-50 m-4 overflow-hidden'>
                <div className='position-absolute bg-primary d-inline-block' style={{top: '-70px', right: '-20px', padding: '8rem 6px', opacity: '100%', rotate: '-45deg'}}/>
                <div className='position-absolute bg-primary d-inline-block' style={{top: '-70px', right: '5px', padding: '8rem 6px', opacity: '80%', rotate: '-45deg'}}/>
                <div className='position-absolute bg-primary d-inline-block' style={{top: '-70px', right: '30px', padding: '8rem 6px', opacity: '60%', rotate: '-45deg'}}/>
            </div>

            {/* <div className='position-absolute start-0 bg-success m-4 d-inline-block' style={{rotate: '0deg', padding: '3rem', top: '30px'}}>
                <div className='position-absolute bg-warning d-inline-block' style={{top: '-70px', left: '40px', padding: '12rem 6px', opacity: '40%', rotate: '-90deg'}}/>
                <div className='position-absolute bg-warning d-inline-block' style={{top: '-70px', left: '55px', padding: '12rem 6px', opacity: '60%', rotate: '-90deg'}}/>
                <div className='position-absolute bg-warning d-inline-block' style={{top: '-70px', left: '70px', padding: '12rem 6px', opacity: '80%', rotate: '-90deg'}}/>
            </div> */}

            {/* <div className='position-absolute end-0 overflow-hidden m-4 d-inline-block' style={{rotate: '45deg', padding: '3rem', top: '30px'}}>
                <div className='position-absolute bg-warning d-inline-block' style={{top: '-70px', left: '80px', padding: '12rem 6px', opacity: '80%', rotate: '-45deg'}}/>
                <div className='position-absolute bg-warning d-inline-block' style={{top: '-70px', left: '105px', padding: '12rem 6px', opacity: '60%', rotate: '-45deg'}}/>
                <div className='position-absolute bg-warning d-inline-block' style={{top: '-70px', left: '130px', padding: '12rem 6px', opacity: '40%', rotate: '-45deg'}}/>
            </div> */}

            {file ? (
            <div className='position-relative d-flex justify-content-center align-items-center mt-2'>
                <div className='position-absolute bg-warning-transparent shadow-nice rounded-pill border-bottom-primary' style={{padding: '0.75rem', bottom: '-20px'}}>
                    <button onClick={() =>window.open(getOrigin() + "/storage/pengumuman/" + file)} className='btn btn-primary-4 text-dark shadow-nice rounded-5 px-5'>Download File <i className='fa fa-arrow-down ms-1'/></button>
                </div>
            </div>
            ) : (
            <div className='position-relative d-flex justify-content-center align-items-center'>
                <div className='position-absolute bg-warning-transparent shadow-nice rounded-pill border-bottom-primary' style={{padding: '0.75rem', bottom: '-20px'}}>
                    <span className='bg-warning-transparent' style={{padding: '0rem 7.2rem'}}/>
                </div>
            </div>
            )}
    </Modal>
    </>
  )
}

export default ModalPrimary