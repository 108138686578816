import React, { useRef } from 'react'
import { saAlert } from "../helpers";

function Table(props) {
    const {className, tipsNone, title, text, textColor, copyTable, customButton, children} = props

    const tableTag = useRef(false);
  const copyToClipboard = () => {
    const elTable = tableTag.current;

    let range, sel;

    // Ensure that range and selection are supported by the browsers
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      // unselect any element in the page
      sel.removeAllRanges();

      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }

      document.execCommand("copy");
    }

    sel.removeAllRanges();

    saAlert(
      "success",
      "Berhasil copy tabel ke papan clip",
      "Data dapat dipaste ke excel maupun word !"
    );
  };
  return (
    <>
    {!tipsNone &&
        <>
        <h6 className='my-0'><i className='fa fa-fw fa-bars-progress me-1'/> {title}</h6>
        <div className='d-flex flex-wrap justify-content-between align-items-center'>
            <p className={`text-sm ${textColor}`}>
                {text ? text : 
                    <>Pada tampilan dekstop, arahkan cursor ke tabel lalu <span className='text-success fw-semibold text-sm'><i className='fa fa-fw fa-sm fa-keyboard'/> Shift</span> + <span className='text-success fw-semibold text-sm'><i className='fa fa-fw fa-sm fa-mouse'/> Scroll Up/Down</span> untuk menggeser tabel.</>   
                }
            </p>
            {copyTable &&
            <button                
                className='btn btn-sm btn-success-2 border-0 mb-3' 
                title='Copy data ke clipboard.'
                onClick={copyToClipboard}
            >
                <i className='fa fa-clipboard'/>
            </button>
            }
            {customButton &&
            <>
            <button
                className='btn btn-success-2 fw-semibold rounded-3 mb-3 mobile-w-100' 
                title='Ubah semua status menjadi hadir.'
                onClick={customButton}
            >
                Hadirkan semua <i className='fa fa-circle-check align-middle ms-1'/>
            </button>
            {/* <p>Klik tombol diatas apabila ingin ubah menjadi hadir semua.</p> */}
            </>
            }
        </div>
        </>
    }

    <div className='table-responsive'>

        <table 
            className={`table table-sm table-borderless ${className}`}
            ref={tableTag}
        >
            {children}
        </table>
    </div>
    </>
  )
}

export const Thead = (props) => {
    const {className, children} = props
    return (
        <thead className={`${className}`}>
            {children}
        </thead>
    )
}
export const Tbody = (props) => {
    const {className, children} = props
    return (
        <tbody className={`${className}`}>
            {children}
        </tbody>
    )
}

export const Tr = (props) => {
    const {className, noSpaceBottom, children} = props
    
    return (
        <>
        <tr className={`align-middle text-center ${className}`}>
            {children}
        </tr>
        {!noSpaceBottom &&
        <tr>
            <td className='py-1' colSpan={100}></td>
        </tr>
        }
        </>
    )
}
export const Th = (props) => {
    const {className, onClick, rowSpan, colSpan, children} = props
    
    return (
        <th onClick={onClick} rowSpan={rowSpan} colSpan={colSpan} className={`bg-primary text-white fw-normal text-sm px-2 py-1 ${className}`}>
           {children} 
        </th>
    )
}
export const Td = (props) => {
    const {className, rowSpan, colSpan, children} = props
    
    return (
        <td rowSpan={rowSpan} colSpan={colSpan} className={`bg-silver text-nowrap ${className}`}>
           {children} 
        </td>
    )
}

export default Table