import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { patchDosen } from "../../api";
import Input from "../../components/Input";
import { saAlert } from "../../helpers";
import { titleState } from "../../storages";
import { tokenState, userState } from "../../storages/auth";

const DosenEditPassword = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);
  const token = useRecoilValue(tokenState);
  const history = useHistory();

  useEffect(() => setTitle(`Update Password ${user.nama}`), [setTitle, user.nama]);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    patchDosen(user.id, { ...form, password_update: true }, token)
      .then((res) => {
        if (res.data === "success") {
          history.push("/");
          saAlert("success", "Berhasil update profile");
        } else if (res.data === "fail") {
          saAlert(
            "warning",
            "Password lama harus benar !",
            "Silahkan cek kembali password lama anda kemudia pilih update"
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            setErrors(err.response.data.errors);
          }
        }
      });
  };

  return (
    <>
    <div className="row">
        <div className="col-lg-12 mt-2 px-2 pt-2">
          <div className="bg-white rounded-3">
            <div className="bg-primary text-white rounded-top-3 shadow py-2">
              <span className="d-flex justify-content-center align-items-center">
                <i className="fa fa-fw fa-pen me-1" /> Ubah Password
              </span>
            </div>
            <div className="px-3 my-4">
            <form onSubmit={handleSubmit}>
              <div className="row gx-3 gy-0">
                <div className="col-lg-4">
                  <Input
                    marginBottom
                    label="Password Lama"
                    type="password"
                    name="old_password"
                    onChange={(e) => {
                      setForm((prevState) => {
                        return { ...prevState, [e.target.name]: e.target.value };
                      });
                    }}
                    error={errors.old_password}
                  />
                </div>
                <div className="col-lg-4">
                  <Input
                    marginBottom
                    label="Password Baru"
                    type="password"
                    name="password"
                    onChange={(e) => {
                      setForm((prevState) => {
                        return { ...prevState, [e.target.name]: e.target.value };
                      });
                    }}
                    error={errors.password}
                  />
                </div>
                <div className="col-lg-4">
                  <Input
                    marginBottom
                    label="Ulangi Password Baru"
                    type="password"
                    name="password_verify"
                    onChange={(e) => {
                      setForm((prevState) => {
                        return { ...prevState, [e.target.name]: e.target.value };
                      });
                    }}
                    error={errors.password}
                  />
                </div>
              </div>
              <div className="mobile-justify-center btn-group mt-2">
                <button type="submit" className="btn-sm btn btn-success-3">
                  Update
                </button>
                <Link className="btn-sm btn btn-dark border-0 shadow-none" to="/">
                  Batal
                </Link>
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DosenEditPassword;
