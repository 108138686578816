import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { showKelas } from "../../api";
import Loading from "../../components/Loading";
// import NoData from "../../components/NoData";
import Table, { Thead, Tbody, Tr, Th, Td } from "../../components/Table";
import { titleState } from "../../storages";
import { tokenState } from "../../storages/auth";
import SmallButton from "../../components/SmallButton";
import Modal from "../../components/Modal";
import { formatDate, getOrigin } from "../../helpers";

const KelasListMhs = () => {
  const { kelas_id } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const setTitle = useSetRecoilState(titleState);
  const title = useRecoilValue(titleState);

  const token = useRecoilValue(tokenState);

  const [items, setItems] = useState({});

  useEffect(
    () => setTitle(`${items.kode ? items.kode : '. . .'}`),
    [setTitle, items.kode]
  );

  const fetchData = useCallback(async () => {
    try {
      const res = await showKelas(kelas_id, token);
      setItems(res.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [kelas_id, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

    return (
      <>
        <div className="row">
          <div className="col-lg-12 mt-2 px-2 pt-2">
            <div className="bg-white rounded-3">
              <div className="bg-primary text-white rounded-top-3 shadow py-2">
                <span className="d-flex justify-content-center align-items-center">
                  <i className="fa fa-fw fa-user-graduate me-1" /> List
                  Mahasiswa Kelas{" "}
                  <span className="ms-1 text-warning fw-semibold">
                    {title}
                  </span>
                </span>
              </div>
              {isLoaded ? (
              <>
              <div className="px-3 my-4">
              {items.mhs && items.mhs.length > 0 ? (
                <>
                    <Table title="Tabel List Mahasiswa" copyTable>
                      <Thead>
                        <Tr>
                          <Th className="rounded-start-3">No</Th>
                          <Th className="border-start border-secondary">
                            Kode
                          </Th>
                          <Th className="border-start border-secondary">
                            Nama
                          </Th>
                          <Th className="border-start border-secondary rounded-end-3">
                            <i className="fa fa-fw fa-cog mt-1"/>
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {items.mhs.filter((i) => parseInt(i.status) !== 9).map((i, index) => {
                          return (
                            <Tr key={index}>
                              <Td className="rounded-start-3">{index + 1}</Td>
                              <Td className="border-start">{i.nim}</Td>
                              <Td className="border-start text-start">
                                {i.nama}
                              </Td>
                              <Td className="border-start text-center rounded-end-3">
                                <LihatProfil mhs={i}/>
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                </>
              ) : (
                <table className="table table-sm table-borderless">
                  <tbody>
                    <tr className="align-middle text-nowrap text-center">
                      <td
                        colSpan={12}
                        className="py-5 bg-silver text-secondary rounded"
                      >
                        <span className="d-block py-5">
                          <i className="fa fa-fw fa-exclamation-circle text-secondary fs-5 me-1" />
                          <p className="my-0">Tidak ada List Mahasiswa.</p>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
              <div className="mt-2">
                <Link
                  to="/kelas-jadwal"
                  className="text-decoration-none text-dark fw-semibold"
                >
                  <i className="fa fa-fw fa-arrow-left" /> Kembali
                </Link>
                </div>
              </div>
              </>
              ) : (
              <Loading />
              )}
              
            </div>
          </div>
        </div>
      </>
    );
};

export const LihatProfil = ({mhs}) => {
  const [modal, setModal] = useState(false);
  return (
    <>
      <SmallButton margin="px-3" type="custom" onClick={() => setModal(true)} buttonColor="primary-3" icon="eye" text="Lihat Profil"/>

      <Modal 
        title={`Profil dari ${mhs.nama}`}
        sizeLg
        modal={modal}
        >

        <div className="row g-0 d-flex align-items-center">
          <div className="col-lg-3 px-lg-3 my-4">
            <div className="d-flex justify-content-center align-items-center img-responsive">
              <img
                src={`${getOrigin()}/storage/foto_mahasiswa/${mhs.cabang
                  }/${mhs.angkatan}/${mhs.nim}.jpg`}
                alt="Tidak ada foto"
                className="img-fluid rounded-3 shadow-profile text-gray-500"
              />
            </div>
          </div>

          <div className="col-lg-9 px-lg-4 pt-lg-4">
            <table className="table-borderless">
              <tbody>
                <tr>
                  <td className="text-gray-500">NIM</td>
                  <td className="px-3 text-white">:</td>
                  <td className="text-white">{mhs.nim}</td>
                </tr>
                <tr>
                  <td className="text-gray-500">Nama</td>
                  <td className="px-3 text-white">:</td>
                  <td className="text-white">{mhs.nama}</td>
                </tr>
                <tr>
                  <td className="text-gray-500">Tempat Lahir</td>
                  <td className="px-3 text-white">:</td>
                  <td className="text-white">{mhs.tempat}</td>
                </tr>
                <tr>
                  <td className="text-gray-500">Tanggal Lahir</td>
                  <td className="px-3 text-white">:</td>
                  <td className="text-white">
                    {formatDate(mhs.tanggal)}
                  </td>
                </tr>
                <tr>
                  <td className="text-gray-500">No HP</td>
                  <td className="px-3 text-white">:</td>
                  <td className="text-white">{mhs.no_hp}</td>
                </tr>
                <tr>
                  <td className="text-gray-500">Alamat</td>
                  <td className="px-3 text-white">:</td>
                  <td className="text-white">{mhs.alamat_ortu}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <button
          type="button"
          className="btn btn-sm btn-dark border-0 shadow-none"
          onClick={() => setModal(false)}
        >
          Kembali
        </button>
      </Modal>
    </>
  )
}

export default KelasListMhs;
