import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import Loading from "../../components/Loading";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { showKelas } from "../../api";
import Table, { Tbody, Td, Th, Thead, Tr } from "../../components/Table";
import { titleState } from "../../storages";
import { tokenState } from "../../storages/auth";
import NilaiCreate from "./Create";
import NoData from "../../components/NoData";
import { getGrade } from "../../helpers";

const Nilai = () => {
  const token = useRecoilValue(tokenState);
  const { kelas_id } = useParams();
  const [isLoading, setIsloading] = useState(false);

  // get Kelas
  const [items, setItems] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await showKelas(kelas_id, token);
      setItems(res.data);
      setIsloading(true);
    } catch (err) {
      console.log(err);
    }
  }, [kelas_id, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const setTitle = useSetRecoilState(titleState);
  const title = useRecoilValue(titleState);

  useEffect(() => {
    setTitle(`${items.kode ? items.kode : '. . .'}`);
  }, [setTitle, items.kode]);

  const getNilai = (kelas, mhs, jenis) => {
    return (kelas.krs &&
      kelas.krs.length > 0 &&
      kelas.krs
        .filter((k) => parseInt(k.mhs_id) === parseInt(mhs.id) && k.nilai)
        .map((k) => k.nilai[jenis]))[0];
  };

  const absensiCount = (mhs) => {
    return (
      items.pengajaran &&
      items.pengajaran.length > 0 &&
      items.pengajaran.filter((p) => {
        return (
          parseInt(p.status) !== 9 &&
          String(p.pertemuan) !== "UTS" &&
          String(p.pertemuan) !== "UAS" &&
          p.absensi.filter(
            (a) =>
              parseInt(a.mhs_id) === parseInt(mhs.id) &&
              parseInt(a.status) === 1
          ).length > 0
        );
      }).length
    );
  };

  const getAngkaAbsensi = (mhs) => {
    return ((absensiCount(mhs) / 12) * 100).toFixed(2);
  };

  // const getPersentasiAbsensi = (mhs, persentasi) => {
  //   let data = ((absensiCount(mhs) / 12) * parseFloat(persentasi)).toFixed(2);
  //   return !isNaN(data) ? data : 0;
  // };

  const getPersentasiTugas = (mhs) => {
    let data = (
      (getNilai(items, mhs, "tugas") * parseFloat(items.persentasi_tugas)) /
      100
    ).toFixed(2);
    return !isNaN(data) ? data : 0;
  };

  const getPersentasiUts = (mhs) => {
    let data = (
      (getNilai(items, mhs, "uts") * parseFloat(items.persentasi_uts)) /
      100
    ).toFixed(2);
    return !isNaN(data) ? data : 0;
  };

  const getPersentasiUas = (mhs) => {
    let data = (
      (getNilai(items, mhs, "uas") * parseFloat(items.persentasi_uas)) /
      100
    ).toFixed(2);
    return !isNaN(data) ? data : 0;
  };

  // const getNilaiTotal = (mhs) => {
  //   return (
  //     parseFloat(
  //       getPersentasiAbsensi(mhs, parseFloat(items.persentasi_kehadiran) ?? 10)
  //     ) +
  //     parseFloat(getPersentasiTugas(mhs)) +
  //     parseFloat(getPersentasiUts(mhs)) +
  //     parseFloat(getPersentasiUas(mhs))
  //   ).toFixed(2);
  // };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 mt-2 px-2 pt-2">
          <div className="bg-white rounded-3">
            <div className="bg-primary text-white rounded-top-3 shadow py-2">
              <span className="d-flex justify-content-center align-items-center">
                <i className="fa fa-fw fa-user-graduate me-1" /> Nilai Kelas{" "}
                <span className="ms-1 text-warning fw-semibold">
                  {title}
                </span>
              </span>
            </div>

            {isLoading ? (
              <>
                {items.mhs && items.mhs.length > 0 ? (
                  <>
                    <NilaiCreate
                      kelas={items}
                      mhs={items.mhs}
                      krs={items.krs}
                      onFetchData={fetchData}
                    />

                    <div className="px-3 my-4">
                      <Table title="Tabel Nilai" copyTable>
                        <Thead>
                          <Tr>
                            <Th className="rounded-start-3">No</Th>
                            <Th className="border-start border-secondary">
                              Kode
                            </Th>
                            <Th className="border-start border-secondary">
                              Nama
                            </Th>
                            <Th className="border-start border-secondary">
                              Absensi{" "}
                              {items.persentasi_kehadiran &&
                                items.persentasi_kehadiran + "%"}
                            </Th>
                            <Th className="border-start border-secondary">
                              Persentasi Absensi
                            </Th>
                            <Th className="border-start border-secondary">
                              Tugas{" "}
                              {items.persentasi_tugas &&
                                items.persentasi_tugas + "%"}
                            </Th>
                            <Th className="border-start border-secondary">
                              Persentasi Tugas
                            </Th>
                            <Th className="border-start border-secondary">
                              UTS{" "}
                              {items.persentasi_uts &&
                                items.persentasi_uts + "%"}
                            </Th>
                            <Th className="border-start border-secondary">
                              Persentasi UTS
                            </Th>
                            <Th className="border-start border-secondary">
                              UAS{" "}
                              {items.persentasi_uas &&
                                items.persentasi_uas + "%"}
                            </Th>
                            <Th className="border-start border-secondary">
                              Persentasi UAS
                            </Th>
                            <Th className="border-start border-secondary">
                              Total
                            </Th>
                            <Th className="border-start border-secondary rounded-end-3">
                              Predikat
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {items.mhs.filter((i) => parseInt(i.status) !== 9).map((i, index) => {
                            return (
                              <Tr key={index}>
                                <Td className="rounded-start-3">{index + 1}</Td>
                                <Td className="border-start px-3">{i.nim}</Td>
                                <Td className="border-start text-start px-3">
                                  {i.nama}
                                </Td>
                                <Td className="bg-silver-active border-start">
                                  {absensiCount(i)}
                                </Td>
                                <Td className="border-start">
                                  {getAngkaAbsensi(i)}
                                </Td>
                                <Td className="bg-silver-active border-start">
                                  {items.krs &&
                                    items.krs
                                      .filter(
                                        (k) =>
                                          parseInt(k.mhs_id) === parseInt(i.id)
                                      )
                                      .map((k) => k.nilai && k.nilai.tugas)}
                                </Td>
                                <Td className="border-start">
                                  {getPersentasiTugas(i)}
                                </Td>
                                <Td className="bg-silver-active border-start">
                                  {items.krs &&
                                    items.krs
                                      .filter(
                                        (k) =>
                                          parseInt(k.mhs_id) === parseInt(i.id)
                                      )
                                      .map((k) => k.nilai && k.nilai.uts)}
                                </Td>
                                <Td className="border-start">
                                  {getPersentasiUts(i)}
                                </Td>
                                <Td className="bg-silver-active border-start">
                                  {items.krs &&
                                    items.krs
                                      .filter(
                                        (k) =>
                                          parseInt(k.mhs_id) === parseInt(i.id)
                                      )
                                      .map((k) => k.nilai && k.nilai.uas)}
                                </Td>
                                <Td className="border-start">
                                  {getPersentasiUas(i)}
                                </Td>
                                <Td className="bg-silver-active border-start">
                                  {items.krs &&
                                    items.krs
                                      .filter(
                                        (k) =>
                                          parseInt(k.mhs_id) === parseInt(i.id)
                                      )
                                      .map((k) => k.nilai && k.nilai.total)}
                                </Td>
                                <Td className="border-start rounded-end-3 fw-semibold text-warning-dark">
                                  {getGrade(
                                    parseInt(
                                      items.krs &&
                                        items.krs
                                          .filter(
                                            (k) =>
                                              parseInt(k.mhs_id) ===
                                              parseInt(i.id)
                                          )
                                          .map((k) => k.nilai && k.nilai.grade)
                                    )
                                  )}
                                </Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </Table>

                      <div className="row mb-3">
                        <div className="col-lg-6">
                          <h6>Keterangan</h6>
                          <table className="table table-sm table-borderless mb-0">
                            <tbody>
                              <tr>
                                <td className="bg-none px-2 border text-center">85 s/d 100</td>
                                <td className="bg-none px-2 border text-center">
                                  <span className="text-warning-dark fw-semibold">
                                    A
                                  </span>
                                </td>
                                <td className="bg-none px-2 border w-75">
                                  <span>Sangat Memuaskan</span>
                                </td>
                              </tr>
                              <tr>  
                                <td className="bg-none px-2 border text-center">{`75 s/d <85`}</td>
                                <td className="bg-none px-2 border text-center">
                                  <span className="text-warning-dark fw-semibold">
                                    B
                                  </span>
                                </td>
                                <td className="bg-none px-2 border w-75">
                                  <span>Memuaskan</span>
                                </td>
                              </tr>
                              <tr>
                                <td className="bg-none px-2 border text-center">{`65 s/d <75`}</td>
                                <td className="bg-none px-2 border text-center">
                                  <span className="text-warning-dark fw-semibold">
                                    C
                                  </span>
                                </td>
                                <td className="bg-none px-2 border w-75">
                                  <span>Cukup</span>
                                </td>
                              </tr>
                              <tr>
                                <td className="bg-none px-2 border text-center">{`45 s/d <65`}</td>
                                <td className="bg-none px-2 border text-center">
                                  <span className="text-warning-dark fw-semibold">
                                    D
                                  </span>
                                </td>
                                <td className="bg-none px-2 border w-75">
                                  <span>Kurang Memuaskan</span>
                                </td>
                              </tr>
                              <tr>
                                <td className="bg-none px-2 border text-center">{`0 s/d <45`}</td>
                                <td className="bg-none px-2 border text-center">
                                  <span className="text-warning-dark fw-semibold">
                                    E
                                  </span>
                                </td>
                                <td className="bg-none px-2 border w-75">
                                  <span>Sangat Kurang</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-lg-6">
                          <h6>Bobot Nilai</h6>
                          <table className="table table-sm table-borderless mb-0">
                            <tbody>
                              <tr>
                                <td className="bg-none px-2 border text-center w-50">Nilai Absensi</td>
                                <td className="bg-none px-2 border text-center w-50">
                                  {items.persentasi_kehadiran}%
                                </td>
                              </tr>
                              <tr>
                                <td className="bg-none px-2 border text-center w-50">Nilai Tugas</td>
                                <td className="bg-none px-2 border text-center w-50">
                                  {items.persentasi_tugas}%
                                </td>
                              </tr>
                              <tr>
                                <td className="bg-none px-2 border text-center w-50">Nilai UTS</td>
                                <td className="bg-none px-2 border text-center w-50">
                                  {items.persentasi_uts}%
                                </td>
                              </tr>
                              <tr>
                                <td className="bg-none px-2 border text-center w-50">Nilai UAS</td>
                                <td className="bg-none px-2 border text-center w-50">
                                  {items.persentasi_uas}%
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="mt-2">
                        <button
                          type="button"
                          className="bg-none border-0 shadow-none fw-semibold"
                          onClick={() => window.history.go(-1)}
                        >
                          <i className="fa fa-fw fa-arrow-left" /> Kembali
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <NoData title="Data mahasiswa kosong." />
                )}
              </>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Nilai;
