import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchPengajaran } from "../api";
import Loading from "../components/Loading";
import NoData from "../components/NoData";
// import Table, { Thead, Tbody, Tr, Th, Td } from "../components/Table";
import { getHari } from "../helpers";
import { titleState, semesterAjaranState } from "../storages";
import { tokenState, userState } from "../storages/auth";
import Modal from "../components/Modal";

const Home = () => {
  const setTitle = useSetRecoilState(titleState);
  const [isLoading, setIsLoading] = useState(false);
  const token = useRecoilValue(tokenState);
  const user = useRecoilValue(userState);
  const semesterAjaran = useRecoilValue(semesterAjaranState);
  // const tahunAjaran = useRecoilValue(tahunAjaranState);

  useEffect(() => {
    setTitle(`Login sebagai ${user.nama}`);
  }, [setTitle, user]);

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (token !== "") {
      const fetchData = async () => {
        try {
          const res = await fetchPengajaran(
            "all",
            token,
            `tanggal=today&semester_ajaran=${semesterAjaran}`
          );
          if (res.data) {
            if (res.data.pengajaran) {
              setItems(res.data.pengajaran);
            }
            setIsLoading(true);
          }
        } catch (err) {
          console.log(err);
        }
      };

      const tO = setTimeout(() => {
        fetchData();
      }, 3000);

      return () => {
        clearTimeout(tO);
      };
    }
  }, [token, semesterAjaran]);

  return (
    <>
          <div className="row">
            <div className="col-lg-12 mt-2 px-2 pt-2">
              <div className="position-static bg-gradient-primary rounded-top-3 p-0">
                <div className="score-background rounded-top-3 shadow-nice p-4">
                  <div>
                    <div>
                      <p className="my-0 text-center text-shadow text-white fw-semibold fst-italic fs-5">
                        TO BE THE{" "}
                        <span className="text-warning fs-5">FIRST CHOICE</span>{" "}
                        OF INDUSTRY
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row g-0 d-flex align-items-center">
                  <div className="col-lg-3 px-4 my-4 mobile-hidden animations">
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        src="/img/logo/alfaprima_white.png"
                        alt="Logo Alfa Prima"
                        width={192}
                        className="img-fluid rounded-3 shadow-profile bg-primary text-gray-500 p-4"
                      />
                    </div>
                  </div>
                  <div className="col-lg-9 px-4 pt-4">
                    <div className="d-flex flex-column justify-content-between">
                      <div>
                        <table className="table table-sm table-borderless">
                          <tbody>
                            <tr>
                              <td className="bg-none text-gray-500 small">
                                Kode Dosen
                              </td>
                              <td className="bg-none text-white small">:</td>
                              <td className="bg-none text-white small">
                                {user.nip}
                              </td>
                            </tr>
                            <tr>
                              <td className="bg-none text-gray-500 small">
                                Nama
                              </td>
                              <td className="bg-none text-white small">:</td>
                              <td className="bg-none text-white small">
                                {user.nama}
                              </td>
                            </tr>
                            <tr>
                              <td className="bg-none text-gray-500 small">
                                No HP
                              </td>
                              <td className="bg-none text-white small">:</td>
                              <td className="bg-none text-white small">
                                {user.no_hp}
                              </td>
                            </tr>
                            <tr>
                              <td className="bg-none text-gray-500 small">
                                Email
                              </td>
                              <td className="bg-none text-white small">:</td>
                              <td className="bg-none text-white small">
                                {user.email}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="btn-group mobile-justify-center shadow mb-4">
                          <Link
                            className="btn btn-sm btn-primary-2 px-4"
                            to="/dosen-edit"
                          >
                            <i className="fa fa-fw fa-pen-to-square"></i> Ubah
                            Profile
                          </Link>
                          <Link
                            className="btn btn-sm btn-warning-2 px-4"
                            to="/dosen-edit-password"
                          >
                            <i className="fa fa-fw fa-key text-dark"></i> Ganti
                            Password
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white">
                <div className="bg-primary-4 text-white rounded-bottom-3 shadow py-2">
                  <span className="d-flex justify-content-center align-items-center">
                    <i className="fa fa-fw fa-chart-line me-1" /> Aktivitas
                    Pengajaran hari ini
                  </span>
                </div>

                <div className="px-3 my-3">
                  {/* <div className="mobile-hidden">
                    <h6 className="mb-2">Kode icon warna :</h6>
                    <div className="row row-cols-2 gy-2 px-3 mb-4">
                      <div className="col-lg-3 px-1">
                        <div className="d-flex align-items-center rounded bg-primary text-white shadow p-2 h-100">
                          <h6 className="my-0 text-sm">
                            <i className="text-gray-500 me-1 fa fa-fw fa-circle" />
                            = Dibuat oleh Akademik
                          </h6>
                        </div>
                      </div>
                      <div className="col-lg-3 px-1">
                        <div className="d-flex align-items-center rounded bg-primary text-white shadow p-2 h-100">
                          <h6 className="my-0 text-sm">
                            <i className="text-primary-2 me-1 fa fa-fw fa-circle-up" />
                            = Diperbarui oleh Akademik
                          </h6>
                        </div>
                      </div>
                      <div className="col-lg-3 px-1">
                        <div className="d-flex align-items-center rounded bg-primary text-white shadow p-2 h-100">
                          <h6 className="my-0 text-sm">
                            <i className="text-success-light me-1 fa fa-fw fa-circle-up" />
                            = Diperbarui oleh UPT
                          </h6>
                        </div>
                      </div>
                      <div className="col-lg-3 px-1">
                        <div className="d-flex align-items-center rounded bg-primary text-white shadow p-2 h-100">
                          <h6 className="my-0 text-sm">
                            <i className="text-primary-2 me-1 fa fa-fw fa-circle-pause fa-fade" />
                            = Kelas dimulai
                          </h6>
                        </div>
                      </div>
                      <div className="col-lg-3 px-1">
                        <div className="d-flex align-items-center rounded bg-primary text-white shadow p-2 h-100">
                          <h6 className="my-0 text-sm">
                            <i className="text-success-light me-1 fa fa-fw fa-circle-check" />
                            = Kelas selesai
                          </h6>
                        </div>
                      </div>
                      <div className="col-lg-3 px-1">
                        <div className="d-flex align-items-center rounded bg-primary text-white shadow p-2 h-100">
                          <h6 className="my-0 text-sm">
                            <i className="text-danger me-1 fa fa-fw fa-circle-xmark" />
                            = Kelas dibatalkan
                          </h6>
                        </div>
                      </div>
                      <div className="col-lg-6 px-1">
                        <div className="d-flex align-items-center rounded bg-primary text-white shadow p-2 h-100">
                          <h6 className="my-0 text-sm">
                            <i className="text-success-light me-1 fa fa-fw fa-circle" />
                            = Kelas Tambahan{" "}
                            <span className="text-gray-500 text-sm">
                              (Kondisi ini muncul di Kode Kelas)
                            </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-bottom mx-2 pt-2 mb-4 mobile-hidden" /> */}


                  {/* <div className="mobile-hidden">
                    {isLoading ? (
                      items && items.length > 0 ? (
                        <Table title="Tabel Pengajaran" copyTable>
                          <Thead>
                          <Tr>
                            <Th className="rounded-start-3">
                              <i className="fa fa-fw fa-cog pt-1" />
                            </Th>
                            <Th className="border-start border-secondary">No</Th>
                            <Th className="border-start border-secondary px-4">
                              Pertemuan
                            </Th>
                            <Th className="border-start border-secondary px-5">
                              Kode
                            </Th>
                            <Th className="border-start border-secondary px-4">
                              Hari & Jam (WITA)
                            </Th>
                            <Th className="border-start border-secondary px-4">
                              Tanggal
                            </Th>
                            <Th className="border-start border-secondary">
                              Jumlah Mhs
                            </Th>
                            <Th className="border-start border-secondary">
                              Mhs Hadir
                            </Th>
                            <Th className="border-start border-secondary">
                              Semester
                            </Th>
                            <Th className="border-start border-secondary">
                              Batal Kelas
                            </Th>
                            <Th className="border-start border-secondary rounded-end-3">
                              Opsi
                            </Th>
                          </Tr>
                          </Thead>
                          <Tbody>
                            {items.map((i, index) => {
                              return (
                                <Tr key={index}>
                                  <Td className="rounded-start-3">
                                    {i.status === 1 && <i className="fa fa-fw fa-lg fa-circle text-secondary pt-1" />}
                                    {i.status === 2 && <i className="fa fa-fw fa-lg fa-circle-up text-primary-2 pt-1" />}
                                    {i.status === 3 && <i className="fa fa-fw fa-lg fa-circle-up text-success-light pt-1" />}
                                    {i.status === 4 && <i className="fa fa-fw fa-lg fa-circle-pause fa-fade text-primary-2" />}
                                    {i.status === 5 && <i className="fa fa-fw fa-lg fa-circle-check text-success-light pt-1" />}
                                    {i.status === 9 && <i className="fa fa-fw fa-lg fa-circle-xmark text-danger pt-1" />}
                                  </Td>
                                  <Td className={`border-start 
                                    ${i.status === 4 && 'bg-silver-active text-blue-dark border-white'}
                                    ${i.status === 5 && 'text-success-2'}
                                    ${i.status === 9 && 'text-danger-dark'}
                                  `}>{index + 1}</Td>
                                  <Td className={`border-start 
                                    ${i.status === 4 && 'bg-silver-active text-blue-dark border-white'}
                                    ${i.status === 5 && 'text-success-2'}
                                    ${i.status === 9 && 'text-danger-dark'}
                                  `}>Pertemuan {i.pertemuan}</Td>
                                  <Td className={`border-start 
                                    ${i.status === 4 && 'bg-silver-active text-blue-dark border-white'}
                                    ${i.status === 5 && 'text-success-2'}
                                    ${i.status === 9 && 'text-danger-dark'}
                                    ${i.custom && 'position-relative'}
                                  `}>
                                    {i.custom && <i className="position-absolute start-0 top-0 pt-2 fa fa-fw fa-circle fa-xs text-success-light" />}
                                    {i.kelas && i.kelas.kode}
                                  </Td>
                                  <Td className={`border-start 
                                    ${i.status === 4 && 'bg-silver-active text-blue-dark border-white'}
                                    ${i.status === 5 && 'text-success-2'}
                                    ${i.status === 9 && 'text-danger-dark'}
                                  `}>{getHari(i.hari)}, {i.dari} - {i.sampai}</Td>
                                  <Td className={`border-start 
                                    ${i.status === 4 && 'bg-silver-active text-blue-dark border-white'}
                                    ${i.status === 5 && 'text-success-2'}
                                    ${i.status === 9 && 'text-danger-dark'}
                                  `}>{i.tanggal}</Td>
                                  <Td className={`border-start 
                                    ${i.status === 4 && 'bg-silver-active text-blue-dark border-white'}
                                    ${i.status === 5 && 'text-success-2'}
                                    ${i.status === 9 && 'text-danger-dark'}
                                  `}>{i.mhs && i.mhs.length}</Td>
                                  <Td className={`border-start 
                                    ${i.status === 4 && 'bg-silver-active text-blue-dark border-white'}
                                    ${i.status === 5 && 'text-success-2'}
                                    ${i.status === 9 && 'text-danger-dark'}
                                  `}>{i.absensi && i.absensi.length > 0
                                  ? i.absensi.filter((i) => parseInt(i.status) === 1)
                                      .length
                                  : "-"}</Td>
                                  <Td className={`border-start 
                                    ${i.status === 4 && 'bg-silver-active text-blue-dark border-white'}
                                    ${i.status === 5 && 'text-success-2'}
                                    ${i.status === 9 && 'text-danger-dark'}
                                  `}>{i.semester}</Td>
                                  <Td className={`border-start 
                                    ${i.status === 4 && 'bg-silver-active text-blue-dark border-white'}
                                    ${i.status === 5 && 'text-success-2'}
                                    ${i.status === 9 && 'text-danger-dark'}
                                  `}>{i.status === 9 ? <DetailBatal btnRounded pengajaran={i} /> : "-"}</Td>
                                  <Td className={`border-start rounded-end-3
                                    ${i.status === 4 && 'bg-silver-active text-blue-dark border-white'}
                                    ${i.status === 5 && 'text-success-2'}
                                    ${i.status === 9 && 'text-danger-dark'}
                                  `}>
                                    <Link
                                      to={`/pengajaran-absensi/${i.id}`}
                                      className="btn btn-sm btn-success-3 rounded-3 text-sm"
                                    >
                                      Absensi & BAP
                                    </Link>
                                  </Td>
                                </Tr>
                              );
                            })}
                          </Tbody>
                        </Table>
                      ) : (
                        <NoData title="Tidak ada aktivitas pengajaran hari ini." />
                      )
                    ) : (
                      <Loading title="Sedang mengecek jadwal hari ini . . ." />
                    )}
                  </div> */}

                  <div>
                    {isLoading ? (
                      items && items.length > 0 ? (
                        <div className="row g-4 rounded-4 row-size pt-0 pb-4 d-flex justify-content-center">
                      {items.map((i, index) => {
                              return (
                              <React.Fragment key={index}>
                                <div className="col-lg-4">
                                  <div
                                    className="position-relative rounded-3 bg-gradient-primary shadow-nice p-4 border-bottom-warning h-100"
                                  >
                                    {i.custom &&
                                    <div className="position-absolute bg-warning p-1 rounded" style={{left: '-12px', top: '-12px', rotate: '-8deg'}}>
                                      <h6 className="text-sm mb-0">Kelas Tambahan</h6>
                                    </div> 
                                    }
                                    <div className="border-bottom border-secondary pb-2 d-flex justify-content-between align-items-start mb-2">
                                      <div>
                                        <h5 className="text-warning fw-semibold fst-italic">
                                          {i.kelas && i.kelas.kode}
                                        </h5>
                                        {/* <h6 className="my-0 text-gray-500 fw-semibold text-sm">
                                          <i className="fa fa-fw fa-bullhorn text-sm me-1" />
                                          {i.matkul &&
                                          parseInt(i.matkul.jenis_matkul) === 1
                                            ? "PRAKTEK"
                                            : i.matkul &&
                                              parseInt(i.matkul.jenis_matkul) === 2
                                            ? "TEORI"
                                            : "-"}
                                        </h6> */}
                                        {i.status === 1 && <h6 className="my-0 text-gray-500 text-sm"><i className="me-1 fa fa-sm fa-fw fa-circle text-secondary" /> Dibuat oleh Akademik</h6>}
                                        {i.status === 2 && <h6 className="my-0 text-gray-500 text-sm"><i className="me-1 fa fa-sm fa-fw fa-circle-up text-primary-2" /> Diperbarui oleh Akademik</h6>}
                                        {i.status === 3 && <h6 className="my-0 text-gray-500 text-sm"><i className="me-1 fa fa-sm fa-fw fa-circle-up text-success-light" /> Diperbarui oleh UPT</h6>}
                                        {i.status === 4 && <h6 className="my-0 text-gray-500 text-sm"><i className="me-1 fa fa-sm fa-fw fa-circle-pause fa-fade text-primary-2" /> Kelas sedang berlangsung</h6>}
                                        {i.status === 5 && <h6 className="my-0 text-gray-500 text-sm"><i className="me-1 fa fa-sm fa-fw fa-circle-check text-success-light" /> Kelas selesai</h6>}
                                        {i.status === 9 && <h6 className="my-0 text-gray-500 text-sm"><i className="me-1 fa fa-sm fa-fw fa-circle-xmark text-danger" /> Kelas dibatalkan</h6>}
                                        {/* {i.custom && <h6 className="my-0 text-gray-500 text-sm"><i className="me-1 fa fa-sm fa-fw fa-circle" /> Kelas tambahan</h6>} */}
                                      </div>
                                      <div>
                                        {i.status === 1 && <i className="fa fa-xl fa-circle text-secondary" />}
                                        {i.status === 2 && <i className="fa fa-xl fa-circle-up text-primary-2" />}
                                        {i.status === 3 && <i className="fa fa-xl fa-circle-up text-success-light" />}
                                        {i.status === 4 && <i className="fa fa-xl fa-circle-pause fa-fade text-primary-2" />}
                                        {i.status === 5 && <i className="fa fa-xl fa-circle-check text-success-light" />}
                                        {i.status === 9 && <i className="fa fa-xl fa-circle-xmark text-danger" />}
                                        {/* {i.custom && <i className="fa fa-xl fa-circle text-success-light" />} */}
                                      </div>
                                    </div>

                                    <table className="table table-sm table-borderless">
                                      <tbody>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Pertemuan
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            Pertemuan {i.pertemuan}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Semester
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            Semester {i.semester}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Nama Mata Kuliah
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {i.matkul && i.matkul.nama}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Dosen
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {i.dosen ? i.dosen.nama : '-'}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Ruangan
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {i.ruangan && i.ruangan.nama}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Hari & Jam
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {getHari(i.hari)}, {i.dari} - {i.sampai}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Tanggal
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {i.tanggal}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Jumlah Mahasiswa
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {i.mhs && i.mhs.length}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Mahasiswa Hadir
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {i.absensi && i.absensi.length > 0
                                            ? i.absensi.filter((i) => parseInt(i.status) === 1)
                                                .length
                                            : "-"}
                                          </td>
                                        </tr>
                                        {/* <tr className="align-middle">
                                          <td className="bg-none small text-gray-500">
                                            Batal Kelas
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {i.status === 9 ? <DetailBatal pengajaran={i} /> : "-"}
                                          </td>
                                        </tr> */}
                                      </tbody>
                                    </table>

                                    <div className="btn-group shadow w-100">
                                      {i.status === 9 ? 
                                        <DetailBatal pengajaran={i} /> 
                                        :
                                        <Link
                                          className="btn btn-sm btn-success-2"
                                          to={`/pengajaran-absensi/${i.id}`}
                                        >
                                          <i className="fa fa-fw fa-edit me-1"></i>
                                          Absensi & BAP
                                        </Link>
                                        }
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                    </div>
                      ) : (
                        <NoData title="Tidak ada aktivitas pengajaran hari ini." />
                      )
                    ) : (
                      <Loading title="Sedang mengecek jadwal hari ini . . ." />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

    </>
  );
};

const DetailBatal = (props) => {
  const { pengajaran } = props;

  const [modal, setModal] = useState(false);

  return (
    <>
      <button
        onClick={() => setModal(true)}
        type="button"
        className="btn btn-sm btn-danger-2 w-100"
      >
        <i className="fa fa-eye me-1"/> Lihat alasan batal
      </button>

      {pengajaran && (
        <Modal
          title='Detail kelas batal'
          modal={modal}
          onClose={() => setModal(false)}
        >
          <div className="row">
            <div className="col-lg-6">
              <p className="mb-0 text-warning text-sm">Pertemuan :</p>
              <p>Pertemuan ke {pengajaran.pertemuan}</p>
            </div>
            <div className="col-lg-6">
              <p className="mb-0 text-warning text-sm">Tanggal :</p>
              <p>{pengajaran.tanggal}</p>
            </div>
          </div>
          <p className="mb-0 text-warning text-sm">Oleh :</p>
          <p>{pengajaran.keterangan.split("||")[1]}</p>
          <p className="mb-0 text-warning text-sm">Keterangan :</p>
          <p>{pengajaran.keterangan.split("||")[0]}</p>
          <div className="text-end mt-4">
            <button
              type="button"
              className="btn btn-sm btn-dark shadow-none border-0"
              onClick={() => setModal(false)}
            >
              Kembali
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Home;
