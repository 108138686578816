import React, { useCallback, useEffect, useState } from 'react'
import { FilterBulan, FilterTahun } from '../../components/layouts/TopContent'
import { getBulan, getOrigin } from '../../helpers';
import { useRecoilValue } from 'recoil';
import { tokenState, userState } from '../../storages/auth';
import { fetchHonor } from '../../api';

function Honor() {

    const realTimeTahun = new Date().getFullYear();
    // const moon = new Date().getMonth() + 1;
    // const realTimeBulan = moon === 1 ? 12 : moon - 1;
    const realTimeBulan = new Date().getMonth() + 1;

    const user = useRecoilValue(userState);
    const token = useRecoilValue(tokenState);
    const [getUrl, setGetUrl] = useState("");
    const [tahun, setTahun] = useState(realTimeTahun);
    const [bulan, setBulan] = useState(realTimeBulan);
    const [loading, setLoading] = useState(true);

    const fetchData = useCallback( async () => {
        setLoading(true);
        try {
            await fetchHonor(tahun, bulan, user, token);
            setGetUrl(`${getOrigin()}/api/storage/dosen/slip-gaji/${tahun}/${bulan}/${user.nip}.pdf`);
            setLoading(false);
        } catch (error) {
            setGetUrl("");
            setLoading(false);
        }
    }, [tahun, bulan, user, token]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

  return (
    <>
      <div className='row'>
        <div className='col-lg-12 mt-2 px-2 pt-2'>
          <div className='bg-white rounded-3'>
            <div className="bg-primary text-white rounded-top-3 shadow py-2">
              <span className='d-flex justify-content-center align-items-center'><i className='fa fa-fw fa-sack-dollar me-1' /> Honor</span>
            </div>
            <div className='px-3 mt-4'>
              <div className='row'>
                <div className='col-lg-6'>
                  <FilterTahun value={realTimeTahun} onChangeTahun={(res) => setTahun(res)}/>
                </div>
                <div className='col-lg-6'>
                  <FilterBulan value={realTimeBulan} onChangeBulan={(res) => setBulan(res)}/>
                </div>
              </div>

                <div className='mt-4'>
                    <HonorView loading={loading} tahun={tahun} bulan={bulan} getUrl={getUrl}/>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const HonorView = ({loading, tahun, bulan, getUrl }) => {

return (
    <div className='mb-4'>
        {loading ? (
            <div className='d-flex justify-content-center align-items-center p-5 text-black rounded-3'>
                <p className='mb-0'><i className='fa fa-fw fa-spinner fa-spin'/> Loading...</p>
            </div>
        ) 
        : 
            getUrl ? (
                <div className="ratio ratio-1x1">
                    <iframe
                        className="ratio ratio-1x1"
                        title="Honor"
                        src={getUrl}
                        allowFullScreen
                    ></iframe>
                </div>
            ) : (
                <div className='d-flex justify-content-center align-items-center p-5 text-black rounded-3'>
                    <div className='text-center'>
                        <i className='fa fa-fw fa-circle-xmark text-danger'/>
                        <p className='mb-0'>Tidak ada file pada bulan {getBulan(bulan)} {tahun}</p>
                    </div>
                </div>
            )
        }
    </div>
)
};

export default Honor