import React from 'react'
import { Link } from "react-router-dom";

function SmallButton(props) {
    const {to, type, buttonColor, icon, margin, onClick, disabled, text, title} = props;
  return (
    <>
        {type === "custom" &&
            <>
                {to ?
                    <Link to={to} className={`btn btn-sm btn-${buttonColor} text-sm py-0 px-1 ${margin} ${disabled && 'disabled'}`} title={title}>
                        <i className={`fa fa-fw fa-sm fa-${icon}`}/> {text}
                    </Link>
                :
                    <button type='button' onClick={onClick} className={`btn btn-sm btn-${buttonColor} text-sm py-0 px-1 ${margin} ${disabled && 'disabled'}`} title={title}>
                        <i className={`fa fa-fw fa-sm fa-${icon}`}/> {text}
                    </button>
                }
            </>
        }
        {type === "create" &&
            <>
                {to ?
                    <Link to={to} className={`btn btn-sm btn-primary-2 text-sm py-0 px-1 ${margin} ${disabled && 'disabled'}`} title={title}>
                        <i className={`fa fa-fw fa-sm fa-plus`}/> {text}
                    </Link>
                :
                    <button type='button' onClick={onClick} className={`btn btn-sm btn-primary-2 text-sm py-0 px-1 ${margin} ${disabled && 'disabled'}`} title={title}>
                        <i className={`fa fa-fw fa-sm fa-plus`}/> {text}
                    </button>
                }
            </>
        }
        {type === "edit" &&
            <>
                {to ?
                    <Link to={to} className={`btn btn-sm btn-success-2 text-sm py-0 px-1 ${margin} ${disabled && 'disabled'}`} title={title}>
                        <i className={`fa fa-fw fa-sm fa-pen`}/> {text}
                    </Link>
                :
                    <button type='button' onClick={onClick} className={`btn btn-sm btn-success-2 text-sm py-0 px-1 ${margin} ${disabled && 'disabled'}`} title={title}>
                        <i className={`fa fa-fw fa-sm fa-pen`}/> {text}
                    </button>
                }
            </>
        }
        {type === "delete" &&
            <>
                {to ?
                    <Link to={to} className={`btn btn-sm btn-danger-2 text-sm py-0 px-1 ${margin} ${disabled && 'disabled'}`} title={title}>
                        <i className={`fa fa-fw fa-sm fa-trash-alt`}/> {text}
                    </Link>
                :
                    <button type='button' onClick={onClick} className={`btn btn-sm btn-danger-2 text-sm py-0 px-1 ${margin} ${disabled && 'disabled'}`} title={title}>
                        <i className={`fa fa-fw fa-sm fa-trash-alt`}/> {text}
                    </button>
                }
            </>
        }
        {type === "show" &&
            <>
                {to ?
                    <Link to={to} className={`btn btn-sm btn-dark border-0 text-sm py-0 px-1 ${margin} ${disabled && 'disabled'}`} title={title}>
                        <i className={`fa fa-fw fa-sm fa-eye`}/> {text}
                    </Link>
                :
                    <button type='button' onClick={onClick} className={`btn btn-sm btn-dark border-0 text-sm py-0 px-1 ${margin} ${disabled && 'disabled'}`} title={title}>
                        <i className={`fa fa-fw fa-sm fa-eye`}/> {text}
                    </button>
                }
            </>
        }
    </>
  )
}

export default SmallButton