import React, { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { checkAuth } from "../api";
import { saAlert } from "../helpers";
import Login from "../pages/Login";
import { semesterAjaranState, tahunAjaranState } from "../storages";
import { tokenState, userState } from "../storages/auth";

const MiddlewareDosen = (props) => {
  const { children } = props;

  const [user, setUser] = useRecoilState(userState);
  const [token, setToken] = useRecoilState(tokenState);
  const setTahunAjaran = useSetRecoilState(tahunAjaranState);
  const setSemesterAjaran = useSetRecoilState(semesterAjaranState);

  // if exist token
  useEffect(() => {
    setToken(
      localStorage.getItem("_tokenDosen")
        ? localStorage.getItem("_tokenDosen")
        : ""
    );
  }, [setToken]);

  // Check token
  useEffect(() => {
    const checkUser = async () => {
      try {
        const res = await checkAuth(token);
        setUser(res.data && res.data.user ? res.data.user : {});
        setTahunAjaran(
          res.data && res.data.tahun_ajaran ? res.data.tahun_ajaran : ""
        );
        setSemesterAjaran(
          res.data && res.data.semester_ajaran ? res.data.semester_ajaran : ""
        );
        // console.log("Is-Dosen");
      } catch (err) {
        if (err.response) {
          if (err.response.status === 401) {
            setToken("");
            setUser({});
            console.log("Is-Not-Mhs");
          }
        }
      }
    };
    if (token !== "") {
      checkUser();
    }
  }, [token, setUser, setToken, setTahunAjaran, setSemesterAjaran]);

  // Give alert if user already
  useEffect(() => {
    if (user.id) {
      setTimeout(() => {
        saAlert("success", "Berhasil login !");
      }, 1000);
    }
  }, [user.id]);

  const isLogin = (_token) => {
    localStorage.setItem("_tokenDosen", _token);
    setToken(_token);
  };

  if (token !== "") {
    if (user.id) {
      return children;
    } else {
      return (
        <div className="vh-100 d-flex justify-content-center align-items-center bg-gradient-primary text-white">
        <div className="text-center">
          <div className="d-flex justify-content-center align-items-center">
            <div className="loadings">
            <div className="authorization"></div>
            <div className="authorization"></div>
            <div className="authorization"></div>
            </div>
          </div>
          <h3>Authorization . . .</h3>
        </div>
      </div>
      );
    }
  } else {
    return <Login onIsLogin={(_token) => isLogin(_token)} />;
  }
};

export default MiddlewareDosen;
