import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { showKelas } from "../../api";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import NoData from "../../components/NoData";
// import Table, { Thead, Tbody, Tr, Th, Td } from "../../components/Table";
import { getHari } from "../../helpers";
import { titleState } from "../../storages";
import { tokenState } from "../../storages/auth";

const KelasPengajaran = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { kelas_id } = useParams();
  const token = useRecoilValue(tokenState);
  const setTitle = useSetRecoilState(titleState);
  const title = useRecoilValue(titleState);

  const [items, setItems] = useState({});

  useEffect(
    () => setTitle(`${items.kode ? items.kode : " . . ."}`),
    [setTitle, items.kode]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await showKelas(kelas_id, token);
        setItems(res.data);
        setIsLoaded(true);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [kelas_id, token]);

  const [showOne, setShowOne] = useState(true);
  const [showTwo, setShowTwo] = useState(false);

  const toggleShowOne = () => {
    setShowOne(true);
    setShowTwo(false);
  };
  const toggleShowTwo = () => {
    setShowOne(false);
    setShowTwo(true);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 mt-2 px-2 pt-2">
          <div className="bg-white rounded-3">
            <div className="bg-primary text-white rounded-top-3 shadow py-2">
              <span className="d-flex justify-content-center align-items-center">
                <i className="fa fa-fw fa-chalkboard me-1" /> Pengajaran Kelas{" "}
                <span className="ms-1 text-warning fw-semibold">{title}</span>
              </span>
            </div>

            {isLoaded ? (
              <>
                <div className="px-3 my-4">
                  {/* <h6 className="mb-2">Kode icon warna :</h6>
                <div className="row row-cols-2 gy-2 px-3 mb-4">
                  <div className="col-lg-3 px-1">
                    <div className="d-flex align-items-center rounded bg-primary text-white shadow p-2 h-100">
                      <h6 className="my-0 text-sm">
                        <i className="text-gray-500 me-1 fa fa-fw fa-circle" />=
                        Dibuat oleh Akademik
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-3 px-1">
                    <div className="d-flex align-items-center rounded bg-primary text-white shadow p-2 h-100">
                      <h6 className="my-0 text-sm">
                        <i className="text-primary-2 me-1 fa fa-fw fa-circle-up" />
                        = Diperbarui oleh Akademik
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-3 px-1">
                    <div className="d-flex align-items-center rounded bg-primary text-white shadow p-2 h-100">
                      <h6 className="my-0 text-sm">
                        <i className="text-success-light me-1 fa fa-fw fa-circle-up" />
                        = Diperbarui oleh UPT
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-3 px-1">
                    <div className="d-flex align-items-center rounded bg-primary text-white shadow p-2 h-100">
                      <h6 className="my-0 text-sm">
                        <i className="text-primary-2 me-1 fa fa-fw fa-circle-pause fa-fade" />
                        = Kelas dimulai
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-3 px-1">
                    <div className="d-flex align-items-center rounded bg-primary text-white shadow p-2 h-100">
                      <h6 className="my-0 text-sm">
                        <i className="text-success-light me-1 fa fa-fw fa-circle-check" />
                        = Kelas selesai
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-3 px-1">
                    <div className="d-flex align-items-center rounded bg-primary text-white shadow p-2 h-100">
                      <h6 className="my-0 text-sm">
                        <i className="text-danger me-1 fa fa-fw fa-circle-xmark" />
                        = Kelas dibatalkan
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-6 px-1">
                    <div className="d-flex align-items-center rounded bg-primary text-white shadow p-2 h-100">
                      <h6 className="my-0 text-sm">
                        <i className="text-success-light me-1 fa fa-fw fa-circle" />
                        = Kelas Tambahan{" "}
                        <span className="text-gray-500 text-sm">
                          (Kondisi ini muncul di Kode Kelas)
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="border-bottom mx-2 pt-2 mb-4" /> */}

                  {items.kode ? (
                    <>
                      <div className="d-flex justify-content-center">
                        <Link
                          to={`/kelas-rekap-absensi/${items.id}`}
                          className="mb-4 btn btn-success-2 rounded shadow mobile-w-100"
                        >
                          <i className="fa fa-fw fa-pen me-1 text-sm" /> Lihat
                          rekap absensi
                        </Link>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <button
                            className={`btn ${
                              showOne === true
                                ? "btn-primary-4-active"
                                : "btn-primary-4"
                            } border-bottom-warning text-white shadow-nice rounded-3 p-3 w-100 d-flex justify-content-between align-items-center mb-4`}
                            type="button"
                            onClick={toggleShowOne}
                            aria-expanded="false"
                          >
                            <div>
                              <h6 className="my-0 fw-semibold text-warning text-start">
                                Kelas Aktif{" "}
                                {
                                  items.pengajaran.filter(
                                    (i) => parseInt(i.status) !== 9
                                  ).length
                                }
                              </h6>
                              {showOne === false ? (
                                <p className="mobile-hidden text-start my-0 text-gray-500 text-sm px-1 pt-2 pb-1">
                                  Klik untuk melihat kelas aktif. . .
                                </p>
                              ) : (
                                <p className="mobile-hidden text-start my-0 text-gray-500 text-sm px-1 pt-2 pb-1">
                                  Menampilkan. . .
                                </p>
                              )}
                            </div>
                            {showOne === true ? (
                              <i className="fs-5 fa fa-fw fa-caret-up" />
                            ) : (
                              <i className="fs-5 fa fa-fw fa-caret-down" />
                            )}
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            className={`btn ${
                              showTwo === true
                                ? "btn-primary-4-active"
                                : "btn-primary-4"
                            } border-bottom-warning text-white shadow-nice rounded-3 p-3 w-100 d-flex justify-content-between align-items-center mb-4`}
                            type="button"
                            onClick={toggleShowTwo}
                            aria-expanded="false"
                          >
                            <div>
                              <h6 className="my-0 fw-semibold text-warning text-start">
                                Kelas Batal{" "}
                                {
                                  items.pengajaran.filter(
                                    (i) => parseInt(i.status) === 9
                                  ).length
                                }
                              </h6>
                              {showTwo === false ? (
                                <p className="mobile-hidden text-start my-0 text-gray-500 text-sm px-1 pt-2 pb-1">
                                  Klik untuk melihat kelas batal. . .
                                </p>
                              ) : (
                                <p className="mobile-hidden text-start my-0 text-gray-500 text-sm px-1 pt-2 pb-1">
                                  Menampilkan. . .
                                </p>
                              )}
                            </div>
                            {showTwo === true ? (
                              <i className="fs-5 fa fa-fw fa-caret-up" />
                            ) : (
                              <i className="fs-5 fa fa-fw fa-caret-down" />
                            )}
                          </button>
                        </div>
                      </div>

                      {showOne &&
                      items.pengajaran &&
                      items.pengajaran.filter((i) => parseInt(i.status) !== 9)
                        .length > 0 ? (
                        <div className="row g-4 d-flex justify-content-center align-items-center">
                          {items.pengajaran
                            .filter((i) => parseInt(i.status) !== 9)
                            .map((i, index) => {
                              return (
                                <div className="col-lg-4" key={index}>
                                  <div className="position-relative rounded-3 bg-gradient-primary shadow-nice p-4 border-bottom-warning h-100">
                                    {i.custom && (
                                      <div
                                        className="position-absolute bg-warning p-1 rounded"
                                        style={{
                                          left: "-12px",
                                          top: "-6px",
                                          rotate: "-8deg",
                                        }}
                                      >
                                        <h6 className="text-sm mb-0">
                                          Kelas Tambahan
                                        </h6>
                                      </div>
                                    )}
                                    <div className="border-bottom border-secondary pb-2 d-flex justify-content-between align-items-start mb-2">
                                      <div>
                                        {/* <h5 className={`${i.pertemuan === 'UTS' || i.pertemuan === 'UAS' ? 'text-danger' : 'text-warning'} fw-semibold fst-italic`}>
                                            {i.pertemuan === 'UTS' || i.pertemuan === 'UAS' ? i.pertemuan : `Pertemuan ${i.pertemuan}`}
                                          </h5> */}
                                        {i.pertemuan !== "UTS" &&
                                        i.pertemuan !== "UAS" ? (
                                          <h5 className="text-warning fw-semibold fst-italic">
                                            Pertemuan {i.pertemuan}
                                          </h5>
                                        ) : (
                                          ""
                                        )}

                                        {i.pertemuan === "UAS" && (
                                          <div className="d-inline-block bg-warning rounded px-2 py-0 mb-2">
                                            <h5 className="mb-0 fw-semibold text-black">
                                              {i.pertemuan}
                                            </h5>
                                          </div>
                                        )}
                                        {i.pertemuan === "UTS" && (
                                          <div className="d-inline-block bg-warning rounded px-2 py-0 mb-2">
                                            <h5 className="mb-0 fw-semibold text-black">
                                              {i.pertemuan}
                                            </h5>
                                          </div>
                                        )}
                                        {parseInt(i.status) === 1 && (
                                          <h6 className="my-0 text-gray-500 text-sm">
                                            <i className="me-1 fa fa-sm fa-fw fa-circle text-secondary" />{" "}
                                            Dibuat oleh Akademik
                                          </h6>
                                        )}
                                        {parseInt(i.status) === 2 && (
                                          <h6 className="my-0 text-gray-500 text-sm">
                                            <i className="me-1 fa fa-sm fa-fw fa-circle-up text-primary-2" />{" "}
                                            Diperbarui oleh Akademik
                                          </h6>
                                        )}
                                        {parseInt(i.status) === 3 && (
                                          <h6 className="my-0 text-gray-500 text-sm">
                                            <i className="me-1 fa fa-sm fa-fw fa-circle-up text-success-light" />{" "}
                                            Diperbarui oleh UPT
                                          </h6>
                                        )}
                                        {parseInt(i.status) === 4 && (
                                          <h6 className="my-0 text-gray-500 text-sm">
                                            <i className="me-1 fa fa-sm fa-fw fa-circle-pause fa-fade text-primary-2" />{" "}
                                            Kelas sedang berlangsung
                                          </h6>
                                        )}
                                        {parseInt(i.status) === 5 && (
                                          <h6 className="my-0 text-gray-500 text-sm">
                                            <i className="me-1 fa fa-sm fa-fw fa-circle-check text-success-light" />{" "}
                                            Kelas selesai
                                          </h6>
                                        )}
                                        {parseInt(i.status) === 9 && (
                                          <h6 className="my-0 text-gray-500 text-sm">
                                            <i className="me-1 fa fa-sm fa-fw fa-circle-xmark text-danger" />{" "}
                                            Kelas dibatalkan
                                          </h6>
                                        )}
                                      </div>
                                      <div>
                                        {parseInt(i.status) === 1 && (
                                          <i className="fa fa-xl fa-circle text-secondary" />
                                        )}
                                        {parseInt(i.status) === 2 && (
                                          <i className="fa fa-xl fa-circle-up text-primary-2" />
                                        )}
                                        {parseInt(i.status) === 3 && (
                                          <i className="fa fa-xl fa-circle-up text-success-light" />
                                        )}
                                        {parseInt(i.status) === 4 && (
                                          <i className="fa fa-xl fa-circle-pause fa-fade text-primary-2" />
                                        )}
                                        {parseInt(i.status) === 5 && (
                                          <i className="fa fa-xl fa-circle-check text-success-light" />
                                        )}
                                        {parseInt(i.status) === 9 && (
                                          <i className="fa fa-xl fa-circle-xmark text-danger" />
                                        )}
                                      </div>
                                    </div>

                                    <table className="table table-sm table-borderless">
                                      <tbody>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Kode Kelas
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {items.kode}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Semester
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            Semester {i.semester}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Nama Mata Kuliah
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {items.matkul && items.matkul.nama}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Dosen
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {items.dosen
                                              ? items.dosen.nama
                                              : "-"}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Ruangan
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {items.ruangan &&
                                              items.ruangan.nama}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Hari & Jam
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {getHari(i.hari)}, {i.dari} -{" "}
                                            {i.sampai}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Tanggal
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {i.tanggal}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Jumlah Mahasiswa
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {items.mhs && items.mhs.length}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-success-light">
                                            Mhs Hadir
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-success-light">
                                            {i.absensi && i.absensi.length > 0
                                              ? i.absensi.filter(
                                                  (a) =>
                                                    parseInt(a.status) === 1 &&
                                                    String(i.pertemuan) ===
                                                      String(a.pertemuan)
                                                ).length
                                              : "-"}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            className={`bg-none small
                                          ${
                                            i.absensi.filter(
                                              (a) =>
                                                parseInt(a.status) !== 1 &&
                                                String(i.pertemuan) ===
                                                  String(a.pertemuan)
                                            ).length === 0
                                              ? "text-gray-500"
                                              : "text-danger-light"
                                          }
                                          `}
                                          >
                                            Mhs Tidak Hadir
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td
                                            className={`bg-none small
                                          ${
                                            i.absensi.filter(
                                              (a) =>
                                                parseInt(a.status) !== 1 &&
                                                String(i.pertemuan) ===
                                                  String(a.pertemuan)
                                            ).length === 0
                                              ? "text-white"
                                              : "text-danger-light"
                                          }
                                          `}
                                          >
                                            {i.absensi && i.absensi.length > 0
                                              ? i.absensi.filter(
                                                  (a) =>
                                                    parseInt(a.status) !== 1 &&
                                                    String(i.pertemuan) ===
                                                      String(a.pertemuan)
                                                ).length
                                              : "-"}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>

                                    <div className="btn-group shadow w-100">
                                      <Link
                                        className="btn btn-sm btn-success-2"
                                        to={`/pengajaran-absensi/${i.id}`}
                                      >
                                        <i className="fa fa-fw fa-edit me-1"></i>
                                        Absensi & BAP
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      ) : (
                        showOne && (
                          <div className="mt-4">
                            <NoData />
                          </div>
                        )
                      )}

                      {showTwo &&
                      items.pengajaran &&
                      items.pengajaran.filter((i) => parseInt(i.status) === 9)
                        .length > 0 ? (
                        <div className="row g-4 d-flex justify-content-center align-items-center">
                          {items.pengajaran
                            .filter((i) => parseInt(i.status) === 9)
                            .map((i, index) => {
                              return (
                                <div className="col-lg-4" key={index}>
                                  <div className="position-relative rounded-3 bg-gradient-primary shadow-nice p-4 border-bottom-warning h-100">
                                    {i.custom && (
                                      <div
                                        className="position-absolute bg-warning p-1 rounded"
                                        style={{
                                          left: "-12px",
                                          top: "-6px",
                                          rotate: "-8deg",
                                        }}
                                      >
                                        <h6 className="text-sm mb-0">
                                          Kelas Tambahan
                                        </h6>
                                      </div>
                                    )}
                                    <div className="border-bottom border-secondary pb-2 d-flex justify-content-between align-items-start mb-2">
                                      <div>
                                        <h5 className="text-warning fw-semibold fst-italic">
                                          Pertemuan {i.pertemuan}
                                        </h5>
                                        {i.status === 1 && (
                                          <h6 className="my-0 text-gray-500 text-sm">
                                            <i className="me-1 fa fa-sm fa-fw fa-circle text-secondary" />{" "}
                                            Dibuat oleh Akademik
                                          </h6>
                                        )}
                                        {i.status === 2 && (
                                          <h6 className="my-0 text-gray-500 text-sm">
                                            <i className="me-1 fa fa-sm fa-fw fa-circle-up text-primary-2" />{" "}
                                            Diperbarui oleh Akademik
                                          </h6>
                                        )}
                                        {i.status === 3 && (
                                          <h6 className="my-0 text-gray-500 text-sm">
                                            <i className="me-1 fa fa-sm fa-fw fa-circle-up text-success-light" />{" "}
                                            Diperbarui oleh UPT
                                          </h6>
                                        )}
                                        {i.status === 4 && (
                                          <h6 className="my-0 text-gray-500 text-sm">
                                            <i className="me-1 fa fa-sm fa-fw fa-circle-pause fa-fade text-primary-2" />{" "}
                                            Kelas sedang berlangsung
                                          </h6>
                                        )}
                                        {i.status === 5 && (
                                          <h6 className="my-0 text-gray-500 text-sm">
                                            <i className="me-1 fa fa-sm fa-fw fa-circle-check text-success-light" />{" "}
                                            Kelas selesai
                                          </h6>
                                        )}
                                        {i.status === 9 && (
                                          <h6 className="my-0 text-gray-500 text-sm">
                                            <i className="me-1 fa fa-sm fa-fw fa-circle-xmark text-danger" />{" "}
                                            Kelas dibatalkan
                                          </h6>
                                        )}
                                      </div>
                                      <div>
                                        {i.status === 1 && (
                                          <i className="fa fa-xl fa-circle text-secondary" />
                                        )}
                                        {i.status === 2 && (
                                          <i className="fa fa-xl fa-circle-up text-primary-2" />
                                        )}
                                        {i.status === 3 && (
                                          <i className="fa fa-xl fa-circle-up text-success-light" />
                                        )}
                                        {i.status === 4 && (
                                          <i className="fa fa-xl fa-circle-pause fa-fade text-primary-2" />
                                        )}
                                        {i.status === 5 && (
                                          <i className="fa fa-xl fa-circle-check text-success-light" />
                                        )}
                                        {i.status === 9 && (
                                          <i className="fa fa-xl fa-circle-xmark text-danger" />
                                        )}
                                      </div>
                                    </div>

                                    <table className="table table-sm table-borderless">
                                      <tbody>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Kode Kelas
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {items.kode}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Semester
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            Semester {i.semester}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Nama Mata Kuliah
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {items.matkul && items.matkul.nama}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Dosen
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {items.dosen
                                              ? items.dosen.nama
                                              : "-"}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Ruangan
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {items.ruangan &&
                                              items.ruangan.nama}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Hari & Jam
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {getHari(i.hari)}, {i.dari} -{" "}
                                            {i.sampai}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="bg-none small text-gray-500">
                                            Tanggal
                                          </td>
                                          <td className="bg-none small text-white">
                                            :
                                          </td>
                                          <td className="bg-none small text-white">
                                            {i.tanggal}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>

                                    <div className="btn-group shadow w-100">
                                      <DetailBatal pengajaran={i} />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      ) : (
                        showTwo && (
                          <div className="mt-4">
                            <NoData />
                          </div>
                        )
                      )}
                    </>
                  ) : (
                    <div className="mt-4">
                      <NoData />
                      <div className="mt-2">
                        <Link
                          to="/kelas-jadwal"
                          className="text-decoration-none text-dark fw-semibold"
                        >
                          <i className="fa fa-fw fa-arrow-left" /> Kembali
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const DetailBatal = (props) => {
  const { pengajaran } = props;

  const [modal, setModal] = useState(false);

  return (
    <>
      <button
        onClick={() => setModal(true)}
        type="button"
        className="btn btn-sm btn-danger-2 rounded-3 text-sm"
      >
        <i className="fa fa-eye me-1" /> Lihat alasan batal
      </button>

      {pengajaran && (
        <Modal
          title="Detail kelas batal"
          modal={modal}
          onClose={() => setModal(false)}
        >
          <div className="row">
            <div className="col-lg-6">
              <p className="mb-0 text-warning text-sm">Pertemuan :</p>
              <p>Pertemuan ke {pengajaran.pertemuan}</p>
            </div>
            <div className="col-lg-6">
              <p className="mb-0 text-warning text-sm">Tanggal :</p>
              <p>{pengajaran.tanggal}</p>
            </div>
          </div>
          <p className="mb-0 text-warning text-sm">Oleh :</p>
          <p>{pengajaran.keterangan.split("||")[1]}</p>
          <p className="mb-0 text-warning text-sm">Keterangan :</p>
          <p>{pengajaran.keterangan.split("||")[0]}</p>
          <div className="text-end mt-4">
            <button
              type="button"
              className="btn btn-sm btn-dark shadow-none border-0"
              onClick={() => setModal(false)}
            >
              Kembali
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default KelasPengajaran;
