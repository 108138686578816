import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { showKelas } from "../../api";
import Loading from "../../components/Loading";
import { getJurusan } from "../../helpers";
import { titleState } from "../../storages";
import { tokenState } from "../../storages/auth";
import Table, { Tbody, Td, Th, Thead, Tr } from "../../components/Table";

const KelasRekapAbsensi = () => {
  const { kelas_id } = useParams();
  const token = useRecoilValue(tokenState);

  const [items, setItems] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await showKelas(kelas_id, token);
        setItems(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [kelas_id, token]);

  const setTitle = useSetRecoilState(titleState);
  const title = useRecoilValue(titleState);
  useEffect(() => setTitle(`${items.kode ? items.kode : '. . .'}`), [setTitle, items.kode]);

  const getAbsensi = (kelas, mhs, pertemuan) => {
    return (
      kelas.pengajaran &&
      kelas.pengajaran.length > 0 &&
      kelas.pengajaran
        .filter((p) => {
          return (
            String(p.pertemuan) === String(pertemuan) &&
            parseInt(p.status) !== 9
          );
        })
        .map((p) => {
          return p.absensi
            .filter((a) => {
              return (
                String(a.pertemuan) === String(pertemuan) &&
                parseInt(a.mhs_id) === parseInt(mhs.id)
              );
            })
            .map((a, index) => {
              return (
                <span key={index}>
                  {parseInt(a.status) === 1 && (
                    <span className="text-success-dark fw-semibold">H</span>
                  )}
                  {parseInt(a.status) === 2 && (
                    <span className="text-warning fw-semibold">I</span>
                  )}
                  {parseInt(a.status) === 3 && (
                    <span className="text-warning fw-semibold">S</span>
                  )}
                  {parseInt(a.status) === 4 && (
                    <span className="text-danger fw-semibold">A</span>
                  )}
                </span>
              );
            });
        })
    );
  };

  const getTanggal = (kelas, pertemuan) => {
    return (
      kelas.pengajaran &&
      kelas.pengajaran.length > 0 &&
      kelas.pengajaran
        .filter((p) => parseInt(p.status) !== 9)
        .map((p, index) => {
          if (String(p.pertemuan) === String(pertemuan)) {
            let arrTanggal = p.tanggal.split("-");
            return (
              <span key={index}>
                {arrTanggal[0]}/ <br />
                {arrTanggal[1]}/ <br />
                {arrTanggal[2].split("20")[1]} <br />
              </span>
            );
          }
          return null;
        })
    );
  };

  const getTanggalIcon = (kelas, pertemuan) => {
    return (
      kelas.pengajaran &&
      kelas.pengajaran.length > 0 &&
      kelas.pengajaran
        .filter((p) => parseInt(p.status) !== 9)
        .map((p, index) => {
          if (String(p.pertemuan) === String(pertemuan)) {
            // let arrTanggal = p.tanggal.split("-");
            return (
              <i className="text-white fa fa-fw fa-calendar-check text-sm" key={index}/>
            );
          }
          return null;
        })
    );
  };

  const [showTanggal, setShowTanggal] = useState(false);

  const toggleTanggal = () => {
    setShowTanggal(!showTanggal);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 mt-2 px-2 pt-2">
          <div className="bg-white rounded-3">
            <div className="bg-primary text-white rounded-top-3 shadow py-2">
              <span className="d-flex justify-content-center align-items-center">
                <i className="fa fa-fw fa-chart-line me-1" /> Rekap Absensi
                Kelas{" "}
                <span className="ms-1 text-warning fw-semibold">{title}</span>
              </span>
            </div>
            {items.id ? (
              <>
              <div className="px-3 my-4">
              <Table title="Tabel Rekap Absensi" copyTable>
                <Thead>
                  <Tr noSpaceBottom>
                    <Th rowSpan="2" className="rounded-start-3">
                      No
                    </Th>
                    <Th
                      rowSpan="2"
                      className="border-start border-secondary px-4"
                    >
                      NIM
                    </Th>
                    <Th
                      rowSpan="2"
                      className="border-start border-secondary px-5"
                    >
                      Nama
                    </Th>
                    <Th
                      rowSpan="2"
                      className="border-start border-secondary px-4"
                    >
                      Jurusan
                    </Th>
                    <Th
                      colSpan="14"
                      className="border-start border-secondary rounded-t-r"
                    >
                      Pertemuan
                    </Th>
                  </Tr>
                  <Tr>
                    <Th className="border-start border-top border-secondary">
                      1
                    </Th>
                    <Th className="border-start border-top border-secondary">
                      2
                    </Th>
                    <Th className="border-start border-top border-secondary">
                      3
                    </Th>
                    <Th className="border-start border-top border-secondary">
                      4
                    </Th>
                    <Th className="border-start border-top border-secondary">
                      5
                    </Th>
                    <Th className="border-start border-top border-secondary">
                      6
                    </Th>
                    <Th className="border-start border-top border-secondary">
                      UTS
                    </Th>
                    <Th className="border-start border-top border-secondary">
                      7
                    </Th>
                    <Th className="border-start border-top border-secondary">
                      8
                    </Th>
                    <Th className="border-start border-top border-secondary">
                      9
                    </Th>
                    <Th className="border-start border-top border-secondary">
                      10
                    </Th>
                    <Th className="border-start border-top border-secondary">
                      11
                    </Th>
                    <Th className="border-start border-top border-secondary">
                      12
                    </Th>
                    <Th className="border-start border-top border-secondary rounded-b-r">
                      UAS
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                {items.mhs &&
                items.mhs.filter((i) => parseInt(i.status) !== 9).map((i, index) => {
                  return (
                    <Tr key={index}>
                      <Td className="rounded-start-3">{index + 1}</Td>
                      <Td className="border-start">{i.nim}</Td>
                      <Td className="border-start text-start">{i.nama}</Td>
                      <Td className="border-start">{getJurusan(i.jurusan_id)}</Td>
                      <Td className="border-start">{getAbsensi(items, i, "1")}</Td>
                      <Td className="border-start">{getAbsensi(items, i, "2")}</Td>
                      <Td className="border-start">{getAbsensi(items, i, "3")}</Td>
                      <Td className="border-start">{getAbsensi(items, i, "4")}</Td>
                      <Td className="border-start">{getAbsensi(items, i, "5")}</Td>
                      <Td className="border-start">{getAbsensi(items, i, "6")}</Td>
                      <Td className="border-start">{getAbsensi(items, i, "UTS")}</Td>
                      <Td className="border-start">{getAbsensi(items, i, "7")}</Td>
                      <Td className="border-start">{getAbsensi(items, i, "8")}</Td>
                      <Td className="border-start">{getAbsensi(items, i, "9")}</Td>
                      <Td className="border-start">{getAbsensi(items, i, "10")}</Td>
                      <Td className="border-start">{getAbsensi(items, i, "11")}</Td>
                      <Td className="border-start">{getAbsensi(items, i, "12")}</Td>
                      <Td className="border-start rounded-end-3">{getAbsensi(items, i, "UAS")}</Td>
                  </Tr>
                  );
                })}
                  {showTanggal === false && (
                    <Tr>
                      <Th
                        onClick={toggleTanggal}
                        colSpan="4"
                        className="rounded-start-3 cursor-pointer"
                      >
                        Tanggal{" "}
                        <i className="fa fa-fw fa-chevron-down fa-sm me-1" />
                        <span className="text-gray-500 text-sm">
                          (Click here to view)
                        </span>
                      </Th>
                      <Td className="bg-primary border-start border-secondary text-white">{getTanggalIcon(items, "1") ? getTanggalIcon(items, "1") : <i className="text-dark fa fa-fw fa-circle text-sm" />}</Td>
                      <Td className="bg-primary border-start border-secondary text-white">{getTanggalIcon(items, "2") ? getTanggalIcon(items, "2") : <i className="text-dark fa fa-fw fa-circle text-sm" />}</Td>
                      <Td className="bg-primary border-start border-secondary text-white">{getTanggalIcon(items, "3") ? getTanggalIcon(items, "3") : <i className="text-dark fa fa-fw fa-circle text-sm" />}</Td>
                      <Td className="bg-primary border-start border-secondary text-white">{getTanggalIcon(items, "4") ? getTanggalIcon(items, "4") : <i className="text-dark fa fa-fw fa-circle text-sm" />}</Td>
                      <Td className="bg-primary border-start border-secondary text-white">{getTanggalIcon(items, "5") ? getTanggalIcon(items, "5") : <i className="text-dark fa fa-fw fa-circle text-sm" />}</Td>
                      <Td className="bg-primary border-start border-secondary text-white">{getTanggalIcon(items, "6") ? getTanggalIcon(items, "6") : <i className="text-dark fa fa-fw fa-circle text-sm" />}</Td>
                      <Td className="bg-primary border-start border-secondary text-white">{getTanggalIcon(items, "UTS") ? getTanggalIcon(items, "UTS") : <i className="text-dark fa fa-fw fa-circle text-sm" />}</Td>
                      <Td className="bg-primary border-start border-secondary text-white">{getTanggalIcon(items, "7") ? getTanggalIcon(items, "7") : <i className="text-dark fa fa-fw fa-circle text-sm" />}</Td>
                      <Td className="bg-primary border-start border-secondary text-white">{getTanggalIcon(items, "8") ? getTanggalIcon(items, "8") : <i className="text-dark fa fa-fw fa-circle text-sm" />}</Td>
                      <Td className="bg-primary border-start border-secondary text-white">{getTanggalIcon(items, "9") ? getTanggalIcon(items, "9") : <i className="text-dark fa fa-fw fa-circle text-sm" />}</Td>
                      <Td className="bg-primary border-start border-secondary text-white">{getTanggalIcon(items, "10") ? getTanggalIcon(items, "10") : <i className="text-dark fa fa-fw fa-circle text-sm" />}</Td>
                      <Td className="bg-primary border-start border-secondary text-white">{getTanggalIcon(items, "11") ? getTanggalIcon(items, "11") : <i className="text-dark fa fa-fw fa-circle text-sm" />}</Td>
                      <Td className="bg-primary border-start border-secondary text-white">{getTanggalIcon(items, "12") ? getTanggalIcon(items, "12") : <i className="text-dark fa fa-fw fa-circle text-sm" />}</Td>
                      <Td className="bg-primary border-start border-secondary text-white rounded-end-3">{getTanggalIcon(items, "UAS") ? getTanggalIcon(items, "UAS") : <i className="text-dark fa fa-fw fa-circle text-sm" />}</Td>
                    </Tr>
                  )}

                  {showTanggal === true && (
                    <Tr>
                      <Th
                        onClick={toggleTanggal}
                        colSpan="4"
                        className="rounded-start-3 cursor-pointer"
                      >
                        Tanggal{" "}
                        <i className="fa fa-fw fa-chevron-up fa-sm me-1" />
                        <span className="text-gray-500 text-sm">
                          (Click to hide)
                        </span>
                      </Th>
                      <Td className="bg-primary border-start border-secondary text-white text-sm">{getTanggal(items, "1")}</Td>
                      <Td className="bg-primary border-start border-secondary text-white text-sm">{getTanggal(items, "2")}</Td>
                      <Td className="bg-primary border-start border-secondary text-white text-sm">{getTanggal(items, "3")}</Td>
                      <Td className="bg-primary border-start border-secondary text-white text-sm">{getTanggal(items, "4")}</Td>
                      <Td className="bg-primary border-start border-secondary text-white text-sm">{getTanggal(items, "5")}</Td>
                      <Td className="bg-primary border-start border-secondary text-white text-sm">{getTanggal(items, "6")}</Td>
                      <Td className="bg-primary border-start border-secondary text-white text-sm">{getTanggal(items, "UTS")}</Td>
                      <Td className="bg-primary border-start border-secondary text-white text-sm">{getTanggal(items, "7")}</Td>
                      <Td className="bg-primary border-start border-secondary text-white text-sm">{getTanggal(items, "8")}</Td>
                      <Td className="bg-primary border-start border-secondary text-white text-sm">{getTanggal(items, "9")}</Td>
                      <Td className="bg-primary border-start border-secondary text-white text-sm">{getTanggal(items, "10")}</Td>
                      <Td className="bg-primary border-start border-secondary text-white text-sm">{getTanggal(items, "11")}</Td>
                      <Td className="bg-primary border-start border-secondary text-white text-sm">{getTanggal(items, "12")}</Td>
                      <Td className="bg-primary border-start border-secondary text-white text-sm rounded-end-3">{getTanggal(items, "UAS")}</Td>
                    </Tr>
                  )}

                  <Tr>
                    <Th colSpan="4" className="rounded-start-3">
                      Paraf Dosen
                    </Th>
                    <Td className="bg-primary border-start border-secondary text-white">
                      <i className="text-dark fa fa-fw fa-circle text-sm" />
                    </Td>
                    <Td className="bg-primary border-start border-secondary text-white">
                      <i className="text-dark fa fa-fw fa-circle text-sm" />
                    </Td>
                    <Td className="bg-primary border-start border-secondary text-white">
                      <i className="text-dark fa fa-fw fa-circle text-sm" />
                    </Td>
                    <Td className="bg-primary border-start border-secondary text-white">
                      <i className="text-dark fa fa-fw fa-circle text-sm" />
                    </Td>
                    <Td className="bg-primary border-start border-secondary text-white">
                      <i className="text-dark fa fa-fw fa-circle text-sm" />
                    </Td>
                    <Td className="bg-primary border-start border-secondary text-white">
                      <i className="text-dark fa fa-fw fa-circle text-sm" />
                    </Td>
                    <Td className="bg-primary border-start border-secondary text-white">
                      <i className="text-dark fa fa-fw fa-circle text-sm" />
                    </Td>
                    <Td className="bg-primary border-start border-secondary text-white">
                      <i className="text-dark fa fa-fw fa-circle text-sm" />
                    </Td>
                    <Td className="bg-primary border-start border-secondary text-white">
                      <i className="text-dark fa fa-fw fa-circle text-sm" />
                    </Td>
                    <Td className="bg-primary border-start border-secondary text-white">
                      <i className="text-dark fa fa-fw fa-circle text-sm" />
                    </Td>
                    <Td className="bg-primary border-start border-secondary text-white">
                      <i className="text-dark fa fa-fw fa-circle text-sm" />
                    </Td>
                    <Td className="bg-primary border-start border-secondary text-white">
                      <i className="text-dark fa fa-fw fa-circle text-sm" />
                    </Td>
                    <Td className="bg-primary border-start border-secondary text-white">
                      <i className="text-dark fa fa-fw fa-circle text-sm" />
                    </Td>
                    <Td className="bg-primary border-start border-secondary text-white rounded-end-3">
                      <i className="text-dark fa fa-fw fa-circle text-sm" />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
              <button
                type="button"
                className="bg-none border-0 shadow-none fw-semibold"
                onClick={() => window.history.go(-1)}
              >
                <i className="fa fa-fw fa-arrow-left" /> Kembali
              </button>
            </div>
              </>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default KelasRekapAbsensi;
