import { atom } from "recoil";

export const titleState = atom({
  key: "titleState",
  default: "",
});

export const tahunAjaranState = atom({
  key: "tahunAjaranState",
  default: "",
});

export const semesterAjaranState = atom({
  key: "semesterAjaranState",
  // Ganjil
  // default: 1,
  // Genap
  // default: 2,
  default: "",
});