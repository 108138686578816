import React from "react";
import reactDom from "react-dom";

const Modal = (props) => {
  const rootModal = document.getElementById("rootModal");

  const { title, modal, sizeSm, sizeLg, children } = props;

  return reactDom.createPortal(
    <div
      className={`modal fade 
        ${modal ? "show d-block" : null}
        ${sizeSm ? "modal-sm" : null}
        ${sizeLg ? "modal-xl" : null}
        `}
      id="exampleModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ backgroundColor: "#00000095" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-gradient-primary-3 text-white">
          <div className="modal-header border-secondary">
            <h5 className="modal-title" id="exampleModalLabel">
              {title}
            </h5>
            {/* <button
              onClick={onClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            /> */}
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>,
    rootModal
  );
};

export default Modal;
