import React from "react";

const Loading = (props) => {
  const { title } = props;
  return (
    <div className="d-flex flex-column justify-content-center align-items-center mb-4">
      <div className="loadings">
        <div className="ring"></div>
        <div className="ring"></div>
        <div className="ring"></div>
      </div>
      {title ? <h6 className="text-secondary"> &nbsp; {title}</h6> : <h5 className="text-secondary"> &nbsp; Loading . . .</h5>}
    </div>
  );
};

export default Loading;
